module.exports = {
  "OEbranch": "master",
  "AIBase": "zh_cn",
  "moduleName": "H5-soda-fe-soda-act",
  "filePath": "/src/i18n",
  "prefix": "soda-act---",
  "noParse": true,
  "version": 0,
  "script": "//img0.didiglobal.com/static/copywriter_h5/H5-soda-fe-soda-act/src/i18n/all.js",
  "getLangCodeString": "{'zh':'zh_cn','es':'es_mx','es419':'es_419','pt':'pt_br','ja':'ja_jp','en':'en_us'}[location.href.split('/')[3].split('-')[0]]"
}