module.exports = {
  "FoodC_C_Detailed_rules_WfnL": "¿Cómo funciona?",
  "FoodC_birthday_nxQg_feGV": "Usos restantes: {{n}}",
  "FoodC_unsubscription_moTV_YOeR": "Usaremos esta información para mejorar tu experiencia.",
  "FoodD_optimization_lXdy_Scjt": "Detalles",
  "FoodC_update_mXRF_lKCK": "Nuevo",
  "FoodC_update_YSxw_ZNzh": "Primer mes",
  "FoodC_shoprank_OYFZ_jaot": "Detalles",
  "FoodC_subscription_uipE_quiy": "Volver",
  "FoodC_V2_kGDN_zGcg": "Al registrarte, se realizará un cargo mensual automático de {{money}} a través de {{didifoodbrand}} a partir del {{date}}. Puedes cancelar tu registro en cualquier momento sin gastos adicionales ni penalizaciones. {{rules}}",
  "FoodC_update_UDFM_jhpN": "Envío gratis en todos los pedidos",
  "FoodC_store_Store_Coupon_szoR": "Cupones de tienda",
  "FoodC_Task_Congratulations_pDXl": "¡Felicidades!",
  "FoodC_V2_RTeI_mEFs": "A partir del {{date}}, se te cobrará automáticamente {{money}} por mes. Puedes cancelar tu suscripción en cualquier momento.",
  "FoodC_CGC_Other_channel_oFak": "Compartir a través de otra aplicación",
  "FoodC_Notifications_Invite_users_Ovxe": "Usuarios invitados",
  "FoodC_C_Off_fkZy": "menos",
  "FoodC_CGC_SaMA_ymHl": "El premio sólo podrá utilizarse para pedidos pagados con tarjeta mediante {{app_name}}. Los premios son intransferibles y no pueden retirarse para ser canjeados por dinero en efectivo.",
  "FoodC_birthday_UhuO_lUDL": "No se pudieron reclamar los cupones. Inténtalo de nuevo más tarde.",
  "FoodC_CGC_XBGw_UVvR": "Las personas que invites deben cumplir con los Términos y Condiciones de la promoción de la app de {{app_name}}. Cada usuario puede obtener un premio sólo {{num}} veces. Todas las personas que invites a participar en la promoción que tengan el mismo número de teléfono, dispositivo y/o cuenta de banco se considerarán el mismo usuario invitado.",
  "FoodC_subscription_skWN_HfEE": "Renovación automática",
  "FoodC_pause_iXVD_owuh": "Tu suscripción de {{brand}} se reactivará automáticamente una vez que termine la pausa y se te realizará un cobro de {{money}} el {{date}}. {{tc}}",
  "FoodC_unsubscription_tNxS_UdhI": "Selecciona un motivo",
  "FoodC_pause_Wukv_RhXR": "Pausar suscripción",
  "FoodC_subscription_StNY_sWmE": "Al registrarte aceptas los {{rules}}.",
  "FoodB_V2_dLkl_pbiF": "Para realizar esta acción, actualiza la aplicación a la versión más reciente.",
  "FoodC_C_Mission_completed_FkCw": "Tarea completada",
  "FoodC_url_reaV_LXaO": "Aceptar",
  "FoodC_update_wBxB_qtoB": "Regístrate en {{brand2}} y disfruta del primer mes gratis. Al registrarte, {{didifoodbrand}} realizará un cobro mensual automático de {{money1}} durante los primeros dos meses y a partir del tercer mes, un cobro mensual de {{money2}}. Podrás cancelar tu registro en cualquier momento sin tarifas adicionales ni penalizaciones. {{rules}}",
  "FoodD_Activity_Completed_tPlA": "Finalizados",
  "FoodC_update_VNPd_BxNP": "Actualización gratis a {{brand1}}",
  "FoodC_CGC_qcLp_cjum": "Esta promoción de la aplicación de {{app_name}} sólo está disponible en zonas seleccionadas, incluyendo: {{city_list}}",
  "FoodC_details_The_following_bPLC": "Disponible en estas tiendas",
  "FoodC_c_Please_try_cHSE": "Por favor, inténtalo de nuevo más tarde.",
  "FoodC_C_View_Coupon_OIhv": "Ver cupones",
  "FoodC_C_Received_DOQd": "Disponible",
  "FoodC_pause_izJa_kTkD": "¿Deseas pausar la suscripción del programa?",
  "FoodC_homepage_Unable_to_ybHC": "No se encontró la ubicación",
  "FoodC_CGC_KVtY_VNSE": "Invita a tus amigos a realizar pedidos a través de {{app_name}}. Recibirás un cupón como premio para ahorrar {{amount}} en el monto final de tu pedido siempre y cuando tu amigo canjee un cupón compartido por ti y complete un pedido sin solicitar un reembolso antes de que finalice la promoción. Sin importar si tu amigo usa o no su cupón.",
  "FoodC_subscription_uwdO_LCcw": "Cupones adicionales",
  "FoodD_optimization_Wiuc_vkvD": "Invitar a un amigo",
  "soda-d-i18n---奖励详情": "Detalles del premio",
  "FoodC_register_cCpr_KUUG": "Pago no realizado",
  "FoodC_words_vHza_gIgE": "Registrarme en {{brand}} ({{money}}/mes)",
  "FoodC_C_No_coupon_DlRu": "No hay paquetes de cupones disponibles.",
  "FoodC_demand_Courier_purchasing_vPGD": "Comprado por el socio repartidor",
  "FoodC_words_TYXi_ntMY": "Luego, {{money}}/mes",
  "FoodC_CGC_WOQY_zerr": "El número de teléfono celular del usuario para solicitar pedidos debe ser el mismo número con el que se registró para recibir los cupones.",
  "FoodC_unsubscription_EyKD_bpPY": "Seguir usando {{brand}}",
  "FoodC_update_KTVU_dCMP": "Ilimitado",
  "soda-d-i18n---没有更多了": "No hay más resultados",
  "FoodC_V2_RUXY_aLKJ": "Método de pago automático",
  "FoodC_Notifications_Congratulations_you_qoKt": "Obtuviste un premio",
  "FoodC_page_Your_invited_sPpR": "El amigo al que invites debe ser un usuario nuevo de {{\t\nproductname_didi_food}}. Los cupones que son aplicables en la primera orden están limitados a uno por usuario (cada usuario debe tener un número de teléfono, un dispositivo y una cuenta de pago únicos)",
  "FoodC_update_QaEe_FhNn": "{{money}} al mes",
  "FoodC_C_Mission_Centre_HafM": "Retos",
  "FoodC_subscription_CYiq_FQpm": "Precio: {{money}}",
  "FoodC_page_Rewards_are_dWeY": "El premio se envía con base en la ciudad donde se encuentre la [persona referida]",
  "FoodC_C_Purchased_YheA": "Comprado",
  "FoodC_register_qELy_skhp": "Por favor, intenta con otro método de pago",
  "FoodC_rule_14_Up_dovx": "El límite es 2 cupones por usuario por día",
  "FoodC_C_Available_DlSP": "Disponibles",
  "sailing.d-api.common.活动时间": "Periodo de vigencia",
  "FoodC_update_PJJg_EEDD": "Próximos 2 meses",
  "FoodC_C_You_will_FoTj": "Recibirás",
  "FoodC_page_The_threshold_yZFq": "Los límites de uso y el periodo de vigencia de los cupones aparecen en el cupón. Los cupones sólo pueden usarse durante el periodo de vigencia indicado",
  "FoodC_c_Sign_in_gRWz": "Iniciar sesión",
  "FoodC_Translation__brand_LAYp": "Cupones de {{brand_name}}",
  "FoodC_update_KqNE_JARP": "Usa {{brand1}} gratis por 30 días",
  "FoodC_subscription_gNZb_xfcd": "Cancelar {{didipackbrand}}",
  "FoodC_page_XCBA_oZij": "pedidos",
  "sailing.c-api-intl.超出配送范围": "Fuera del límite de entrega",
  "FoodC_birthday_uoAk_Rmzj": "Pulsa aquí para ver tus cupones",
  "FoodC_unsubscription_xxOV_zoQL": "¿Por qué quieres cancelar tu registro?",
  "FoodD_2_Single_amount_LbPB": "Pedidos",
  "FoodD_optimization_WVNI_kCwK": "Queda(n) {{day}} día(s) y {{hour}} hora(s) para invitar",
  "FoodC_C_No_suitable_UnyN": "No hay promociones disponibles ahora",
  "FoodC_subscription_JZaZ_ToBk": "Paga sólo {{money1}} el primer mes para registrarte en {{didipackbrand}}. {{didifoodbrand}} realizará un cargo automático de {{money2}} el siguiente mes. Puedes cancelar tu registro en cualquier momento sin tarifas adicionales ni penalizaciones.",
  "FoodC_V2_ryoa_JKea": "Agregar un método de pago",
  "FoodC_pause_LiWX_icgn": "¡Tu suscripción se ha reactivado!",
  "FoodC_pause_pfAh_qUrD": "Pausar suscripción",
  "FoodC_C_Purchase_Records_CIUH": "Historial",
  "FoodC_subscription_arhI_yUex": "Conservar registro",
  "FoodC_pause_xeiI_PONf": "No, gracias",
  "FoodC_birthday_mwOE_zhiz": "Entendido",
  "FoodC_pause_AtFL_nncW": "Suscripción pausada",
  "FoodC_didipack_XQlZ_sNjR": "Al registrarte y activar el {{didipackbrand}} se te cobrará sólo {{money1}} durante el primer mes, y al siguiente mes se realizará un cobro automático de {{money2}} en la aplicación de {{didifoodbrand}}. Puedes cancelar tu registro en cualquier momento sin cargos adicionales ni afectaciones. Si solicitas la cancelación de tu registro una vez que ya se haya realizado el pago del mes en curso, tu registro se cancelará al mes siguiente.",
  "FoodC_birthday_bthp_qePu": "Confirmar",
  "FoodD_optimization_jCaj_SrWJ": "No hay programas de premios activos en esta ciudad",
  "FoodC_c_Login_for_puGR": "Inicia sesión para ver más detalles",
  "FoodC_C_Coupon_Purchase_zdkl": "Historial de compras",
  "FoodC_pause_cYix_reoR": "Tu suscripción de {{brand}} se pondrá en pausa el {{date}}. Mientras esté en pausa, no se te realizará ningún cobro y no recibirás las ventajas del programa.",
  "FoodC_C_Payment_money_XOtx": "Comprar por {{money}}",
  "FoodC_C_Buy_again_ScTm": "Puedes volver a comprarlo en {{countdown}}",
  "FoodC_button_Download_DUuD": "Descargar {{product_name}}",
  "FoodC_update_ZFGk_VVRa": "Ver detalles",
  "FoodC_CGC_Copy_MtAU": "Copiar",
  "FoodC_pause_NJOj_iqnX": "La suscripción del programa está en pausa.",
  "FoodD_Activity_Single_Step_xtWh": "Premio por volumen de pedidos",
  "FoodC_update_BIRW_NftU": "Usa {{brand1}} gratis por 30 días (Cámbiate de {{brand2}})",
  "FoodC_Notifications_Invite_successful_RBjt": "Invitaciones exitosas",
  "FoodC_C_Time_of_LACt": "Fecha de compra",
  "FoodC_V2_RlIh_OuIP": "Método de pago",
  "FoodC_page_ZTLT_FASC": "¿No te quedan cupones? Consigue más cupones de envíos gratis aquí",
  "FoodC_birthday_teJB_YsTQ": "Cumpleaños: {{date}}",
  "FoodC_birthday_lxme_nZrh": "Indica la fecha de tu cumpleaños",
  "FoodC_C_Go_tdhw": "Pedir ahora",
  "FoodC_shoprank_Qwab_yWBb": "Entendido",
  "FoodC_CGC_Cruel_departure_Smts": "Ahora no",
  "FoodC_C_Buy_again_iOeQ": "Comprar de nuevo en {{day}} día(s)",
  "FoodC_C_Unlimited_store_Ujro": "¡Aprovecha esta promo y ahórrate {{money}} de la tarifa de entrega en tus próximos pedidos!",
  "FoodC_register_mezq_UlpA": "Entendido",
  "FoodC_words_ikBq_XDxq": "Regístrate en {{brand}} por {{money}}",
  "FoodD_Activity_In_progress_NrnP": "Activos",
  "FoodC_update_pgdT_nUsj": "Podrías ahorrar aproximadamente {{money}} al mes",
  "FoodC_C_No_coupon_JTJL": "No hay paquetes de cupones disponibles para compra.",
  "FoodC_CGC_Go_and_fnbj": "¡Canjear ahora!",
  "FoodC_V2_SvHk_RdVM": "¡Felicidades! Ya te suscribiste a {{didipackbrand}}",
  "FoodD_optimization_MkEn_uAUM": "Prueba con otra ciudad",
  "FoodC_Task_Successfully_received_jXDz": "Has recibido {{coupon}} cupón(es).",
  "FoodC_C_Collection_rqwP": "Obtener",
  "FoodC_subscription_LiqM_lRQw": "DiDi Club",
  "FoodC_CGC_kRMT_EBEL": "Toda persona que invites debe realizar un pedido a través de {{app_name}} para que puedas recibir el premio. No se podrán obtener premios por pedidos realizados mediante otras aplicaciones o canales.",
  "FoodC_C_Universal_Coupon_qLGm": "Cupón universal",
  "FoodC_C_Used_tgaq": "Usado",
  "FoodC_C_Only_money_ZEKz": "Paga sólo {{money}}",
  "FoodC_CGC_QXwb_BKmD": "Periodo de vigencia: del {{startdate}} al {{enddate}}",
  "FoodC_member_qXYs_kKZA": "Entendido",
  "FoodC_C_Expired_AEpg": "Vencido",
  "FoodB_Part2_Detailed_description_mxuF": "Detalles",
  "FoodC_CGC__name_Mjiz": "{{name}} obtuvo un descuento de {{x}}",
  "FoodC_CGC_Invite_immediately_dQRx": "Enviar invitación",
  "FoodB_supplement_Network_connection_RVcl": "Falló la conexión. Comprueba tu configuración de red.",
  "FoodC_page_Rewards_will_NhMd": "Tu premio se agregará a tu cuenta dentro de las 48 horas de haber realizado una invitación exitosa. Para ver los premios, ve a {{\t\nproductname_didi_food}} > Billetera > Cupones",
  "FoodC_subscription_pODC_SAYm": "Vencida",
  "FoodC_Notifications__end_QQPI": "Termina en {{end_time}} días",
  "FoodC_page_Upon_discovery_baTq": "El cupón no puede cambiarse ni canjearse por efectivo. El incumplimiento de los términos y condiciones del cupón puede dar lugar a la recuperación del monto relevante y la suspensión de la cuenta, y puede haber sanciones de acuerdo a la ley",
  "FoodC_C_To_be_Qemy": "Disponible",
  "FoodC_V2_syWI_awMo": "Tarjeta de crédito/débito",
  "FoodC_CGC_gLdR_yRZb": "En caso de incumplimiento de los Términos y Condiciones de uso de la aplicación, se bloqueará temporalmente la cuenta.",
  "FoodC_button_Open_fmKT": "Abrir {{product_name}}",
  "FoodD_Activity_Rewards_will_TMCp": "Los premios se calculan después de que termine el evento.",
  "FoodC_subscription_YJLt_vjMk": "Regístrate en {{didipackbrand}}",
  "FoodC_birthday_JshR_FWPW": "La fecha no podrá modificarse una vez ingresada",
  "FoodB_20230410_YNjt_xAWA": "DiDi Top",
  "FoodC_birthday_ZEpi_KbUs": "Los cupones fueron reclamados correctamente",
  "FoodC_pause_VaEr_OWMS": "Suscripción pausada",
  "FoodC_didipack_uwFV_TgLK": "Regístrate y ahorra aprox. {{money}} al mes.",
  "FoodC_Notifications_Invite_user_GtIH": "Invita a {{user_num}} personas más y obtén {{gift}}",
  "FoodC_words_MBwE_Wmhg": "{{money}} el primer mes",
  "FoodC_birthday_hJRO_UaMS": "Reclamar cupones de cumpleaños",
  "soda-b-web-i18n---休息中": "Tienda cerrada",
  "FoodC_invite_Step_2_AhBV": "Paso 2: El usuario nuevo se registra y realiza su primer pedido",
  "FoodC_subscription_RApp_AMMC": "Al registrarte en {{didipackbrand}}, aceptas los {{rules}}. Se te realizará un cobro mensual de {{money}} el día de facturación. Puedes cancelar tu registro en cualquier momento sin costo.",
  "FoodC_Notifications_Congratulations_gTCr": "¡Felicidades!",
  "sailing.banner-intl.暂停营业": "Cerrado temporalmente",
  "FoodC_CGC_xGpr_jdrf": "Si tienes alguna pregunta sobre esta promoción, contacta a Soporte {{app_name}}",
  "FoodC_unsubscription_oSsY_xaBg": "Cuéntanos más (opcional)",
  "FoodC_page_Invite_friends_oGuj": "Invita a un amigo a probar {{\t\nproductname_didi_food}}. Si canjea el cupón que le enviaste y completa un pedido sin ningún reembolso dentro de los 14 días de haber recibido tu invitación y antes de que el evento termine, incluso si no usa el cupón en su pedido, recibirás un [cupón] de {{\t\nproductname_didi_food}}",
  "FoodC_invite_Step_3_VjuY": "Paso 3: Obtén un cupón",
  "FoodC_subscription_CtEg_IEoy": "Cancelar {{didipackbrand}}",
  "FoodC_C_You_will_pQqU": "Recibirás {{n}} cupones",
  "FoodC_subscription_yHJx_Ospz": "Vigencia: {{date1}} – {{date2}}",
  "FoodC_C_Coupon_Center_WghP": "Centro de paquetes de cupones",
  "FoodC_page_This_special_fYuo": "Esta promoción sólo se ofrece en áreas de {{\t\nactivity_city}}, donde {{\t\nproductname_didi_food}} esté disponible",
  "FoodC_page_For_any_IWNZ": "Si tienes alguna pregunta sobre esta promoción, contacta a Soporte DiDi en {{contact_email}}",
  "FoodD_Activity_Expand_more_VNNc": "Ver más",
  "FoodC_words_bshC_QpQz": "Pagar {{brand}} {{money}} el primer mes",
  "FoodC_unsubscription_PWOD_DyeO": "¡Registro exitoso!",
  "FoodC_C_Terms_and_eAhU": "Términos y Condiciones",
  "FoodC_pause_aidJ_DeYv": "Tu suscripción fue cancelada.",
  "FoodC_update_lgoe_CKtm": "Gratis",
  "FoodC_C_Invitation_CPGR": "Invitar",
  "FoodC_c_Please_log_pWNK": "Inicia sesión en tu cuenta.",
  "FoodC_subscription_eceZ_mHfd": "Se te cobrará automáticamente {{money}} el {{date}}. Puedes cancelar tu registro en cualquier momento sin cargos adicionales ni penalizaciones.",
  "FoodD_optimization_KHwe_UDpu": "Ver todos",
  "FoodC_didipack_kIYJ_MqHw": "El siguiente mes se realizará un cobro automático de {{money2}} en la aplicación de {{didifoodbrand}}. Puedes cancelar tu registro en cualquier momento sin cargos adicionales ni afectaciones. Si solicitas la cancelación de tu registro una vez que ya se haya realizado el pago del mes en curso, tu registro se cancelará al mes siguiente.",
  "FoodC_invite_Step_1_IIde": "Paso 1: Invita a un usuario nuevo",
  "FoodD_Activity_Number_of_vXPs": "Premio por personas referidas",
  "FoodC_c_Unable_to_cPrS": "Servicio no disponible",
  "FoodC_C_Back_Home_opQY": "Ir a la página de inicio",
  "FoodD_optimization_Qqcn_TvQv": "Sin invitaciones. Ver historial",
  "FoodD_optimization_pADD_hRLc": "Más opciones",
  "FoodC_member_wEQc_Appv": "Ya incluiste {{brand}}en tu pedido actual. Sólo tiene que completarse el pedido para que entre en vigencia.",
  "FoodC_Notifications_Rewards_received_czXX": "Premios obtenidos",
  "FoodC_all_You_haven_lSmt": "Aún no invitas a tus amigos. Intenta enviar una invitación.",
  "FoodC_Notifications_Accept_the_wTgP": "Aceptó la invitación",
  "FoodC_pause_dEKK_qnxf": "La suscripción del programa se ha puesto en pausa y las ventajas como miembro no estarán disponibles durante {{num}} mes(es) a partir del {{date1}}. Tu suscripción de {{brand}} se reactivará el {{date2}} y se te realizará un cobro automático de {{money}}. {{tc}}",
  "FoodC_subscription_KlqY_fMjr": "Vigente",
  "FoodC_member_fKsy_UJNP": "Ver pedido",
  "FoodC_C_Use_jLVF": "Usar",
  "FoodC_birthday_MdbO_KlNx": "Ingresa tu fecha de nacimiento",
  "FoodC_C__n_tBvE": "{{n}} cupones",
  "FoodD_optimization_rIEQ_UWUa": "Compartir",
  "FoodC_C_Maximum_deduction_UvNt": "Hasta {{money}} menos",
  "FoodC_CGC_There_is_gZQn": "¡Aprovecha! Aún tienes {{x}} para canjear en tu siguiente pedido",
  "FoodC_member_rIZz_EbdA": "Estás a punto de obtener el programa",
  "FoodC_CGC_JFyK_TjqE": "Los límites de uso y la vigencia de los cupones aparecen en los detalles del cupón. Los cupones sólo pueden usarse durante el periodo de vigencia indicado",
  "FoodC_subscription_XfQl_RWPP": "Registro",
  "FoodC_c_Try_again_lIZQ": "Reintentar",
  "FoodC_unsubscription_qlCR_GqbU": "Cancelar registro",
  "FoodC_birthday_vcey_tequ": "Ver cupones",
  "FoodC_update_FOFJ_UkRW": "5 % de descuento en todas tus solicitudes de viaje",
  "FoodC_page_hbyT_BKde": "Envío gratis",
  "FoodC_C_Coupon_package_aeUS": "Paquete de cupones",
  "FoodC_subscription_HZKf_jObw": "No tienes una suscripción activa",
  "FoodD_optimization_hPwx_hGCS": "Historial",
  "FoodC_subscription_Jnyy_Zokk": "Tu registro de {{didipackbrand}} se está renovando. Por favor, revísalo más tarde.",
  "FoodC_C_More_TIyU": "Más",
  "FoodC_C_Up_to_boqc": "--",
  "FoodC_V2_NigJ_XaWd": "Registrarse en {{didipackbrand}}",
  "FoodC_update_iSQF_topi": "Sin tarifa de servicio en todos los pedidos",
  "FoodC_update_rJfl_rGTF": "Sin tarifas de servicio",
  "FoodC_STORE_sDEb_djsn": "Antojos en tendencia",
  "FoodC_subscription_Wlpe_bBkf": "Historial",
  "sailing.act-api-intl.活动已经结束": "Lo sentimos, este evento ya finalizó.",
  "FoodC_419_zBRR_jLNF": "Sin pedido mínimo",
  "FoodC_C_No_purchase_cMRq": "No hay historial de compras.",
  "FoodC_C_Full_money_oqcE": "Gasta más de {{money}}",
  "FoodC_update_fIEP_oDcY": "Registrarse",
  "FoodC_unsubscription_tnHb_xMuO": "Siguiente",
  "FoodC_C_You_have_EjAA": "Recibiste",
  "FoodC_update_ZTvS_WSTC": "Envío gratis",
  "FoodD_Activity_No_activity_fhqd": "No hay historial de premios.",
  "FoodC_V2_qWhb_IjVg": "Registrarse",
  "FoodC_update_fyhl_EPiz": "5% de dto. en viajes",
  "FoodC_didipack_ruhC_zsFU": "Regístrate en {{didipack}} por {{money}}",
  "FoodC_CGC_PFoL_QAIo": "Un cupón puede usarse únicamente cuando el monto de un pedido supere el valor mínimo del cupón. Sólo puede usarse un cupón por pedido. Cualquier monto que exceda el valor del cupón será pagado por el usuario.",
  "FoodC_pause_qsCr_nszu": "Reactivar suscripción",
  "FoodC_CGC_wkuZ_BrqU": "Los premios se envían con base en la ciudad en que se encuentre la [persona invitada]",
  "FoodC_register_WoRy_tjec": "Selecciona un método de pago",
  "FoodC_Notifications_Completed_pEhY": "Completado",
  "FoodC_cash_wnPC_qiCD": "Usar",
  "FoodC_update_VTVc_ZtNA": "De ahí en adelante",
  "FoodC_C_Purchase_amount_RXkR": "Monto",
  "FoodC_subscription_uoAf_TiHz": "Gracias por usar {{didipackbrand}}. Si cancelas tu registro, tu {{didipackbrand}} vencerá el {{date}}. ¿Deseas continuar?",
  "FoodC_C_Mission_expired_vxOU": "No hay tareas disponibles por el momento.",
  "FoodC_subscription_QaXu_EIIa": "Administrar {{didipackbrand}}",
  "FoodC_C_My_coupon_fmaV": "Mis cupones",
  "FoodC_V2_SSNq_tsZF": "Entendido",
  "FoodC_subscription_VPMP_seXT": "Términos y Condiciones",
  "FoodC_subscription_jyMF_Ybuq": "Actualmente estás disfrutando de una prueba gratuita de {{brand}}. Si la cancelas ahora, la prueba gratuita expirará inmediatamente y no podrás aprovechar envío gratis en pedidos ni otras ventajas para miembros.",
  "FoodC_C_Order_assignments_xxGZ": "Reto de pedidos",
  "FoodC_subscription_DzKZ_WhvD": "Fecha de compra: {{date}}",
  "FoodD_Activity_Single_reward_ZrZS": "Premio por volumen de pedidos",
  "FoodC_CGC_has_successfully_CrTW": "{{x}} amigos invitados",
  "FoodC_update_TDwr_XZVd": "Mi {{brand2}}",
  "FoodC_all_No_reward_ocGJ": "Sin premios",
  "FoodC_CGC_UFif_hfJM": "Los premios se enviarán a tu cuenta en un plazo de {{num}} h después de haber completado un equipo y cumplir con los requisitos de la promoción. Puedes ver tus premios en la sección de “Cupones” de la aplicación {{app_name}}.",
  "FoodC_C_Go_VWzj": "Pedir ahora",
  "FoodC_C_The_end_yvSK": "Termina en",
  "FoodC_center_Ospi_SNsH": "Ahorro total",
  "FoodC_page_eUPd_sDHg": "Detalles",
  "FoodD_Activity_Number_of_OgKt": "Premio por personas referidas",
  "FoodC_pause_tfPj_IAoM": "Tu suscripción de {{brand}} se reactivará el {{date}} y se te realizará un cobro automático de {{money}}. {{tc}}",
  "soda-d-i18n---无任何记录": "Vacío",
  "FoodC_C_Bonded_package_lroO": "Tienda de paquetes de cupones",
  "当前无活动": "Actualmente no hay eventos.",
  "该城市暂无活动": "Actualmente no hay eventos en esta ciudad.",
  "取消": "Cancelar",
  "网络异常，请稍后再试": "Error de red. Inténtalo más tarde.",
  "手机号码错误": "Número de celular no válido",
  "选择国家或地区": "Seleccionar país o región",
  "暂时不需要%s的服务": "No estoy usando actualmente el servicio de %s",
  "支付成功": "Pago exitoso",
  "我们正在努力": "En proceso",
  "是，半年之内不要给我发邮件": "Confirmo que no quiero recibir correos electrónicos en los próximos 6 meses.",
  "将优惠分享到": "Compartir promoción en",
  "邮件退订结果": "Resultado de cancelación de correo electrónico",
  "暂无活动": "No hay eventos actualmente.",
  "推荐记录": "Historial de referencia",
  "恭喜你获得%s张优惠券，快去使用吧": "¡Felicidades! Tienes un cupón de %s. Úsalo para pedir ahora.",
  "X推D": "Referencias X-D",
  "X推C": "Usuario referido por X",
  "不支持现金支付": "Efectivo no aceptado",
  "该浏览器不支持自动复制": "La copia automática no es compatible con este navegador",
  "对此类邮件不感兴趣": "No estoy interesado en este tipo de correos electrónicos.",
  "退订理由：": "Razón para darse de baja:",
  "登录": "Iniciar sesión",
  "输入手机号码获取优惠券": "Ingresa tu teléfono y obtén tu descuento.",
  "现金": "Efectivo",
  "仅限%s": "Sólo %s",
  "规则详情": "Detalles",
  "您邀请的好友需通过%s下单，通过其他渠道下单不发放邀请奖励": "El amigo que invites debe realizar un pedido mediante %s. Los pedidos realizados por otros canales no cumplirán el requisito para recibir un premio por invitación.",
  "折": " ",
  "是，再也不要给我发邮件了": "Confirmo que no quiero recibir correos electrónicos en el futuro.",
  "输入手机号": "Ingresa tu número",
  "在这里购买！": "¡Adquiérelos aquí!",
  "阶梯奖励": "Premios en escala",
  "很遗憾，未能领取成功": "Lo sentimos; no se puede aplicar esta promoción",
  "活动时间": "Tiempo de promoción",
  "隐藏": "Ocultar",
  "%s可用": "Aplicable en %s",
  "满%s可用": "más de %s",
  "T&C": "Términos y condiciones",
  "售罄": "Agotado",
  "当前定位城市：": "Ciudad actual establecida：",
  "券包": "Cupón",
  "附近没有商家": "No hay ninguna tienda cercana",
  "请登录": "Regístrate.",
  "选择城市": "Seleccionar ciudad",
  "您已经成功领取优惠券": "Cupón recibido exitosamente",
  "兑换成功": "Canjeado",
  "更多详情": "Más detalles",
  "可获得%s优惠券": "Recibe %s cupones.",
  "轻松来获得一个非常棒的优惠券包": "Pedir a tu antojo tiene premios",
  "请开启定位": "Activar los servicios de localización",
  "支付超时！": "Tiempo de espera de la solicitud agotado",
  "奖励会在邀请成功后的48小时内发放入您的账号，您可在%s-钱包-券中查看": "Los premios se enviarán a tu cuenta dentro de las 48 horas siguientes a la invitación. Para visualizarlas, abre la aplicación %s > Billetera > Cupones",
  "去升级": "Actualizar",
  "复制成功": "Copiado",
  "免配送费": "Envío gratis",
  "重新加载": "Volver a subir",
  "支付": "Pagar",
  "%s日内有效": "Válido por %s día(s)",
  "有效时间:": "Válido hasta:",
  "当前无有效活动": "No hay promociones activas.",
  "周四": "Jueves",
  "本优惠活动仅限%s[在线支付]的订单享受，不可提现转赠": "Esta promoción sólo está disponible para pedidos [con pago en línea] de %s. No se puede transferir o canjear por efectivo.",
  "通过SNS邀请": "Compartir en redes sociales",
  "分钟": "Min",
  "恭喜你！兑换成功！": "¡Felicidades, cupón canjeado!",
  "优惠券中心": "Centro de cupones",
  "我的优惠券": "Mis cupones",
  "网络繁忙，请稍后再试": "Red ocupada. Inténtalo más tarde.",
  "很遗憾您无法接收我们的最新消息了，感谢您使用%s！": "Lamentamos que no quieras recibir nuestros mensajes. ¡Gracias por utilizar %s!",
  "奖励进度": "Progreso de premios",
  "领取成功": "¡Canjeado!",
  "周五": "Viernes",
  "周二": "Martes",
  "券包购买已超限制": "La compra del cupón ha superado el límite.",
  "与": "y",
  "最高抵扣%s": "Aplicable a %s",
  "您邀请的好友必须是%s新用户，每人仅限享受1次新用户首单优惠，同一手机号、同一设备、同一支付账号视为同一用户": "El amigo que invites debe ser un nuevo usuario de %s. Cada persona sólo puede obtener un descuento por primer pedido de usuario nuevo una sola vez. El mismo número de teléfono, el mismo dispositivo y la misma cuenta de pago se consideran un mismo usuario.",
  "邮件频率过高": "Recibo demasiados correos electrónicos.",
  "收餐人手机号与领取券时输入的手机号和账号注册手机号必须为同一号码": "El número de teléfono del destinatario del pedido, el número ingresado al canjear el cupón y el número utilizado para registrar la cuenta deben ser el mismo.",
  "当前手机系统不支持自动复制": "El sistema móvil actual no es compatible con la copia automática.",
  "仅售": "Sólo",
  "兑换优惠券": "Canjear cupón",
  "收起": "Ver menos",
  "大家都在领取奖励": "Todos reciben premios.",
  "配送费": "Tarifa de entrega",
  "支付中": "Pagar",
  "在您的订单中使用这些优惠！": "¡Úsalos en lo que quieras! ¡Se puede usar con promociones en la tienda!",
  "已获得奖励": "Premios obtenidos",
  "SMS": "SMS",
  "恭喜你获得一张%s优惠券，快去使用吧": "¡Felicidades! Tienes un cupón de %s. Úsalo para pedir ahora.",
  "复制": "Copiar",
  "我知道了": "Entendido",
  "《法律声明与隐私政策》": "Los Términos y condiciones",
  "OFF": " ",
  "兑换中": "En curso",
  "周三": "Miércoles",
  "已参与过同类型活动</br>快去下单吧": "Ya participaste en una promoción similar</br>. Continúa y realiza tu pedido.",
  "看看其他的活动": "Ver las otras actividades.",
  "周一": "Lunes",
  "隐私政策》": "El aviso de privacidad",
  "推荐有奖": "Premios por referencias",
  "同意": "Acepto",
  "周日": "Domingo",
  "在购买后的一个月内都可以使用": "Cupones disponibles hasta un mes después de la compra",
  "优惠券使用门槛及有效期详见券面提示，未在券有效期限内使用完毕的，不可继续使用": "Los detalles de uso y el periodo de vigencia se muestran en el cupón. Los cupones no se pueden usar una vez terminado ese periodo.",
  "邮件退订": "Darse de baja de los correos electrónicos",
  "输入兑换码": "Ingresa un código promocional.",
  "详细规则": "Detalles de las reglas",
  "Share": "Compartir",
  "你已经打开过这个礼盒了": "Ya abriste este regalo.",
  "知道了": "Después",
  "运营活动": "Promoción de operaciones",
  "定位服务未开启，需要先开启定位服务才能参与活动！": "Los servicios de ubicación no están habilitados. Para participar en esta promoción, recuerda habilitarlos primero.",
  "购买": "Comprar",
  "券账号：%s": "Cuenta actual: %s",
  "查看其它活动": "Ver otros eventos",
  "新用户额外惊喜奖励": "¿Nuevo en DiDi Food? Disfruta más premios.",
  "%s天后过期": "Expira en %s día(s)",
  "单笔订单金额满足优惠券使用门槛金额时方可使用，单笔订单只能使用一张优惠券，使用优惠后，如订单金额超出部分需用户自行支付": "Se puede usar un cupón cuando un pedido alcanza el monto mínimo del cupón. Sólo se puede usar un cupón por pedido. El usuario debe pagar cualquier diferencia del valor del cupón.",
  "可抵扣%s": "Aplicable a %s",
  "仅限%s使用": "Sólo %s",
  "领取记录": "Historial de premios",
  "立即兑换": "Canjear ahora",
  "如果您退订邮件，可能会错过重要消息，您确定不再接收%s给您发送的邮件吗？": "Si te das de baja, dejarás de recibir mensajes importantes. ¿Confirmas que quieres dejar de recibir estos correos electrónicos?",
  "周六": "Sábado",
  "待入账": "Pendiente",
  "点击领取": "Obtener ahora",
  "落地页": "Página de destino",
  "C运营弹窗": "Ventana emergente de operaciones de C",
  "POS": "Terminal punto de venta",
  "添加信用卡/借记卡": "Agregar tarjeta de crédito/débito",
  "活动说明": "Acerca de las promociones",
  "Email": "Correo electrónico",
  "领取失败": "No se pudo reclamar.",
  "网络似乎存在问题，请稍后重试": "Parece ser un problema de red. Inténtalo más tarde.",
  "分享给朋友，一起享优惠吧！": "¡Comparte y recibe descuentos!",
  "《法律声明": "Términos y condiciones",
  "当日有效": "Válido sólo hoy",
  "有效期至": "Válido hasta",
  "支付方式": "Método de pago",
  "邀请": "Invitar",
  "等待支付结果": "Esperando el resultado del pago...",
  "应付金额": "Importes a pagar",
  "购买含4张$10mxn无门槛券的优惠券包仅需支付$20mxn": "Adquiere un paquete de 4 cupones de $10 pagando solamente $20",
  "是，一年之内不要给我发邮件": "Confirmo que no quiero recibir correos electrónicos en los próximos 12 meses.",
  "仅限新用户使用": "Sólo para nuevos usuarios",
  "一经发现任何违规、套现行为，将追回相关金额或者封停账号，且依法追究其法律责任": "Cualquier violación de las reglas o intento de cobro de un cupón puede resultar en la deducción de dicha cantidad o la suspensión de la cuenta. Aquellos que violen las reglas pueden también ser responsables legalmente.",
  "退订": "Darse de baja",
  "网络不佳，请检查您的网络": "Mala conexión. Comprueba tu conexión de Internet.",
  "重试": "Inténtalo de nuevo",
  "查看更多": "Ver",
  "请选择支付方式": "Selecciona un método de pago.",
  "%s 人": "%s persona(s)",
  "我的邀请码": "Mi código para referir",
  "获取优惠券": "Obtener cupones",
  "我的奖励": "Mis premios",
  "C端落地页": "Página de inicio de DiDi Food",
  "兑换失败": "No se puede canjear.",
  "待入账奖励": "Premios pendientes",
  "加载失败": "No se puede cargar.",
  "必须同意用户条款，才能继续下一步": "Antes de continuar, haz clic en Términos y condiciones",
  "附近商家": "Tiendas cercanas",
  "暂无优惠券": "No hay cupones disponibles.",
  "卡类型": "Tipo de tarjeta",
  "格式不正确": "Formato incorrecto",
  "订单": "Pedidos",
  "不可用原因": "Motivo no válido",
  "请您升级到最新的APP版本，体验外卖功能": "Actualiza la versión de la aplicación para empezar a pedir comida.",
  "邀请一个好友即可获得": "Invita a un amigo para recibir cupones",
  "返回首页": "Volver a la página de inicio",
  "没有更多了": "Fin de resultados",
  "去预订食物": "Realiza un pedido ahora",
  "More": "Más",
  "有效期至%s": "Válido hasta %s",
  "去看看": "Volver a la página de inicio",
  "兑换失败，请再试一次": "Error. Por favor, inténtalo de nuevo.",
  "确认": "Confirmar",
  "已过期": "Expiró.",
  "本优惠活动仅限[%s]开通%s区域": "Esta promoción sólo está disponible en las zonas en [%s] donde %s está disponible.",
  "此活动尚未在您的城市开通": "Evento no disponible en tu ciudad",
  "确定": "Aceptar",
  "去使用": "Usar ahora",
  "%s": "%s",
  "当天过期": "Válido para hoy",
  "去看看其他的": "Ver otros",
  "具体活动规则见": "Consulta los términos y condiciones aplicables.",
  "条款和条件": "Términos y condiciones",
  "配送": "Envío",
  "为你的第一单领取优惠": "¡Recibe descuentos para tu primer pedido!",
  "通用支付": "Todos los medios de pago",
  "99Pay-余额": "99Pay - Saldo",
  "券被领完": "No hay más cupones",
  "卡": "Tarjeta",
  "优惠券已放入": "Cupón enviado",
  "关于本活动的任何问题，请联系%s客服": "Para cualquier pregunta respecto a esta promoción, ponte en contacto con Soporte DiDi de %s al ",
  "邀请好友成为%s新用户，好友领取您送出的红包，并在活动时间截止前完成订单且无退款，无论是否使用领取的红包下单，您均可获得%s[优惠券]奖励": "Invita a un amigo a ser un nuevo usuario de %s para que utilice un cupón enviado por ti. Si tu amigo realiza un pedido antes del plazo de la promoción y no devuelve el pedido, no importa si usa el cupón para realizar el pedido, podrás recibir %s [Cupones] premios",
  "推荐骑手": "¡Refiere a un repartidor!",
  "活动已结束": "Lo sentimos, este evento ya finalizó.",
  "今日领取次数达上限": "Se alcanzó el límite diario.",
  "FoodC_V1_Gift_box_UWqz": "You already opened this gift",
  "FoodC_1_Maximum_number_cJPy": "Daily limit reached",
  "FoodC_2_Enter_mobile_jXbU": "Enter mobile number",
  "FoodOpC_city_Rewards_are_oURm": "Reward is issued based on the city in which the [Person Referred] is located",
  "soda-m-i18n---奖励规则": "Reward Rules",
  "FoodC_1_Coupon_received_EEkp": "No coupons left",
  "FoodC_1_Coupon_Account_kcLB": "Current account: {{phone_number}}",
  "DiDi_Food_(C)_Suspended_Delivery_status_Delivery_Pause_PIPS": "Temporarily Unavailable",
  "FoodC_V1_Click_receive_fBbu": "Claim Now",
  "FoodC_coupons_Congratulations_on_YevG": "Congrats! You received a coupon",
  "FoodC_1_Receiving_Records_RumV": "Rewards History",
  "FoodC_1_Unable_to_yqfz": "Unable to claim",
  "FoodC_V1_Use_IuaW": "Use Now",
  "FoodC_V1_View_activity_NCOP": "View Other Events",
  "FoodC_copyaddJP_Your_mobile_zrXT": "Don’t worry, your number will only be used to claim your event coupon",
  "soda-m-i18n---完成": "Complete",
  "soda-m-i18n---取消": "Cancel",
  "FoodC_1_Additional_Surprise_bBNh": "New customers enjoy extra rewards",
  "Gattaran_reffral_Kjwv_iVSZ": "Invite Friends",
  "FoodC_1_Share_it_scKc": "Share with friends to enjoy discounts!",
  "FoodC_1_Everyone_is_Tdgu": "Everyone is getting rewards",
  "FoodC_toast__SVYJ": "Offer claimed"
}