module.exports = {
  "FoodC_C_Detailed_rules_WfnL": "注意事項",
  "FoodC_page_Upon_discovery_baTq": "規約違反や不正行為が発覚された場合、割引は適用されず、アカウントのブロックや法的責任を追及される場合があります。",
  "FoodC_C_To_be_Qemy": "未使用",
  "FoodC_button_Open_fmKT": "{{product_name}}を開く",
  "FoodC_store_Store_Coupon_szoR": "店舗クーポン",
  "FoodC_Task_Congratulations_pDXl": "おめでとうございます！",
  "FoodD_Activity_Rewards_will_TMCp": "ボーナスはイベント終了後加算されます",
  "FoodC_coupons_Congratulations_on_YevG": "クーポンを獲得しました",
  "FoodC_C_Off_fkZy": "OFF",
  "soda-b-web-i18n---休息中": "受付停止中",
  "sailing.banner-intl.暂停营业": "受付一時停止中",
  "FoodC_1_Receiving_Records_RumV": "クーポン受取履歴",
  "FoodC_page_Invite_friends_oGuj": "招待されたお友達が下記3点を満たした場合、 あなたは{{ productname_didi_food}}[クーポン]を獲得できます。 ・お友達が{{ productname_didi_food}}に新規ユーザー登録をする ・初回注文前にあなたが送ったクーポンコードを入力 ・キャンペーン期間内、かつ紹介された日より7日以内に注文を完了する（クーポン利用有無は関係ありません）",
  "FoodC_C_You_will_pQqU": "以下{{n}}枚のクーポンを獲得できます",
  "FoodC_C_Coupon_Center_WghP": "クーポンパック一覧",
  "FoodC_V1_Gift_box_UWqz": "このクーポンは取得済みです",
  "FoodC_C_Mission_completed_FkCw": "ミッションをクリア",
  "FoodC_1_Unable_to_yqfz": "受信できませんでした",
  "FoodC_V1_Use_IuaW": "クーポンを利用する",
  "FoodC_page_This_special_fYuo": "本キャンペーン適用対象はDiDi Foodサービス開始済みエリアとなります。",
  "FoodD_Activity_Completed_tPlA": "終了済み",
  "FoodC_page_For_any_IWNZ": "本キャンペーンについてのお問い合わせは、{{contact_email}}カスタマーセンターまでお願いします。",
  "FoodD_Activity_Expand_more_VNNc": "さらに表示",
  "FoodC_details_The_following_bPLC": "以下の店舗で利用可能",
  "FoodC_C_View_Coupon_OIhv": "クーポンを確認",
  "FoodC_C_Terms_and_eAhU": "利用規約と条件",
  "FoodC_1_Maximum_number_cJPy": "クーポンを取得できませんでした",
  "FoodC_C_Received_DOQd": "受取済み",
  "FoodC_C_Invitation_CPGR": "紹介する",
  "FoodC_homepage_Unable_to_ybHC": "位置情報取得に失敗",
  "FoodC_V1_View_activity_NCOP": "開催中のキャンペーン",
  "soda-d-i18n---奖励详情": "インセンティブ詳細",
  "FoodC_2_Enter_mobile_jXbU": "携帯電話番号を入力",
  "FoodD_Activity_Number_of_vXPs": "紹介人数別ボーナス",
  "FoodC_demand_Courier_purchasing_vPGD": "配達員購入代行",
  "FoodC_C_Back_Home_opQY": "トップページへ戻る",
  "FoodC_copyaddJP_Your_mobile_zrXT": "お客様の携帯電話番号は、今回のキャンペーンで獲得したクーポンの連携のためにのみ使用いたします。",
  "soda-d-i18n---没有更多了": "この他にはありません",
  "FoodC_page_Your_invited_sPpR": "招待されたお友達は{{\t\nproductname_didi_food}}の新規ユーザーであることが必要です。携帯電話番号、デバイス、クレジットカード番号などの決済情報のいずれかが重複している場合、同ーユーザーと見なされます。",
  "FoodC_C_Mission_Centre_HafM": "おトクなミッション",
  "FoodC_page_Rewards_are_dWeY": "ボーナスは【被紹介者】の所在都市に応じて発行されます。",
  "FoodC_C_Purchased_YheA": "購入済み",
  "soda-m-i18n---完成": "完了",
  "FoodC_all_You_haven_lSmt": "まだお友達を紹介していません。今すぐ紹介しましょう",
  "FoodC_C_Available_DlSP": "利用可能なクーポン",
  "sailing.d-api.common.活动时间": "イベント実施期間",
  "FoodOpC_city_Rewards_are_oURm": "ボーナスは【被紹介者】の所在都市に応じて発行されます",
  "FoodC_C_You_will_FoTj": "獲得できるクーポン",
  "FoodC_page_The_threshold_yZFq": "クーポンの使用条件および有効期限はクーポンの注意事項をご確認ください。",
  "FoodC_Translation__brand_LAYp": "{{brand_name}}クーポン",
  "FoodC_C_Use_jLVF": "クーポンを利用する",
  "FoodC_C__n_tBvE": "クーポン{{n}}枚",
  "FoodC_C_Maximum_deduction_UvNt": "最大{{money}}割引",
  "sailing.c-api-intl.超出配送范围": "配達エリア外です",
  "FoodD_2_Single_amount_LbPB": "注文数",
  "FoodC_C_No_suitable_UnyN": "適用できるキャンペーンがありません",
  "FoodC_C_Coupon_package_aeUS": "クーポンパック",
  "soda-m-i18n---取消": "キャンセル",
  "FoodC_C_Purchase_Records_CIUH": "購入履歴",
  "FoodC_C_More_TIyU": "もっと見る",
  "FoodC_1_Additional_Surprise_bBNh": "新規のお客様には新規限定クーポンの用意もあります",
  "FoodC_C_Up_to_boqc": "最大{{money}}割引",
  "sailing.act-api-intl.活动已经结束": "キャンペーン終了済み",
  "FoodC_C_Full_money_oqcE": "{{money}}以上のご注文に適用",
  "FoodC_C_You_have_EjAA": "獲得済み",
  "FoodD_Activity_No_activity_fhqd": "現在、イベント参加履歴はありません",
  "FoodC_C_Coupon_Purchase_zdkl": "クーポンパック購入履歴",
  "FoodC_C_Payment_money_XOtx": "購入金額{{money}}",
  "FoodC_C_Buy_again_ScTm": "{{countdown}}後に再度購入できます",
  "FoodC_button_Download_DUuD": "{{product_name}}をダウンロード",
  "FoodD_Activity_Single_Step_xtWh": "注文数別ボーナス",
  "FoodC_C_Time_of_LACt": "購入日時",
  "FoodC_C_Go_tdhw": "注文する",
  "soda-m-i18n---奖励规则": "インセンティブ規則",
  "FoodC_C_Purchase_amount_RXkR": "購入金額",
  "FoodC_C_Buy_again_iOeQ": "{{day}}日後に再度購入できます",
  "FoodC_C_Unlimited_store_Ujro": "全店舗対象クーポン",
  "FoodC_C_Mission_expired_vxOU": "現在進行中のタスクはありません",
  "FoodD_Activity_In_progress_NrnP": "進行中",
  "FoodC_C_My_coupon_fmaV": "マイクーポン",
  "FoodC_1_Coupon_received_EEkp": "クーポンは終了しました",
  "FoodC_C_Order_assignments_xxGZ": "注文回数ミッション",
  "FoodC_1_Share_it_scKc": "お友達にシェアして、クーポンをゲットしよう！",
  "FoodD_Activity_Single_reward_ZrZS": "人数別特別ボーナス",
  "FoodC_Task_Successfully_received_jXDz": "クーポン{{coupon}}枚を獲得しました。",
  "FoodC_1_Coupon_Account_kcLB": "クーポン送付先：{{phone_number}}",
  "FoodC_all_No_reward_ocGJ": "ボーナスはありません",
  "DiDi_Food_(C)_Suspended_Delivery_status_Delivery_Pause_PIPS": "配達員が不足中",
  "FoodC_1_Everyone_is_Tdgu": "みんなでDiDi Food クーポンをゲットしよう！",
  "FoodC_C_Go_VWzj": "注文する",
  "FoodC_C_Collection_rqwP": "受け取る",
  "FoodC_toast__SVYJ": "受取成功",
  "FoodC_C_The_end_yvSK": "期限まで",
  "FoodC_V1_Click_receive_fBbu": "クーポンを受け取る",
  "FoodC_C_Universal_Coupon_qLGm": "全品対象クーポン",
  "FoodC_C_Used_tgaq": "利用済み",
  "FoodC_C_Only_money_ZEKz": "お支払い必要金額はわずか{{money}}",
  "FoodD_Activity_Number_of_OgKt": "人数別特別ボーナス",
  "FoodC_C_Expired_AEpg": "期限切れ",
  "FoodB_Part2_Detailed_description_mxuF": "詳細説明",
  "FoodB_supplement_Network_connection_RVcl": "ネットワークに接続されていません。ネットワークの設定を確認してください",
  "soda-d-i18n---无任何记录": "空",
  "FoodC_page_Rewards_will_NhMd": "クーポンは招待されたお友達が条件達成後、48時間以内にアカウントに付与いたします。{{\t\nproductname_didi_food}} → 「お友達紹介クーポン 」→ 「クーポン一覧」にて確認することができます。",
  "FoodC_C_Bonded_package_lroO": "クーポンパックストア",
  "推荐有奖": "お友達紹介クーポン",
  "同意": "同意",
  "当前无活动": "Currently no events",
  "周日": "日曜日",
  "该城市暂无活动": "この地域では紹介イベントは実施しておりません",
  "在购买后的一个月内都可以使用": "Available for use within one month after purchase",
  "取消": "戻る",
  "网络异常，请稍后再试": "ネットワークエラー。しばらくしてからもう一度お試しください",
  "手机号码错误": "携帯電話番号が間違っています",
  "优惠券使用门槛及有效期详见券面提示，未在券有效期限内使用完毕的，不可继续使用": "クーポンの使用条件および有効期限はクーポンの注意事項をご確認ください。期限切れのクーポンはご利用できません",
  "选择国家或地区": "国や地域を選択",
  "邮件退订": "メール配信停止",
  "输入兑换码": "クーポンコードの入力",
  "详细规则": "注意事項",
  "Share": "Share",
  "暂时不需要%s的服务": "今は%sのサービスは不要です",
  "支付成功": "決済成功",
  "我们正在努力": "一生懸命頑張っています",
  "是，半年之内不要给我发邮件": "はい、半年間はメールを送信しないでください",
  "将优惠分享到": "クーポンコードをシェア",
  "你已经打开过这个礼盒了": "このクーポンは取得済みです",
  "邮件退订结果": "メール配信停止結果",
  "暂无活动": "現在キャンペーンはありません",
  "推荐记录": "紹介履歴",
  "知道了": "Later",
  "恭喜你获得%s张优惠券，快去使用吧": "おめでとうございます。%s枚のクーポンを獲得しました。お早めにご利用ください",
  "X推D": "XがDを紹介",
  "X推C": "XがCを紹介",
  "不支持现金支付": "現金以外での支払いが可能です",
  "运营活动": "マーケティング",
  "该浏览器不支持自动复制": "このブラウザは自動コピーに対応していません",
  "对此类邮件不感兴趣": "メールの内容に興味がない",
  "定位服务未开启，需要先开启定位服务才能参与活动！": "位置情報サービスが有効になっていません。位置情報サービスをオンにするとこのキャンペーンに参加することができます！",
  "购买": "購入",
  "退订理由：": "配信停止理由：",
  "登录": "登録",
  "券账号：%s": "クーポン送付先：%s",
  "查看其它活动": "開催中のキャンペーンを確認する",
  "新用户额外惊喜奖励": "New customers enjoy extra rewards",
  "%s天后过期": "%s日後に期限切れ",
  "输入手机号码获取优惠券": "携帯電話番号を入力してクーポンをゲット",
  "现金": "現金",
  "单笔订单金额满足优惠券使用门槛金额时方可使用，单笔订单只能使用一张优惠券，使用优惠后，如订单金额超出部分需用户自行支付": "一回の注文金額がクーポンの使用条件金額を満たした場合に限りご使用出来ます。一回の注文でご使用できるクーポンは一枚までとなります。なお注文金額がクーポン額より高い場合は差額をお支払い頂く必要があります",
  "可抵扣%s": "%sから割引",
  "仅限%s使用": "%sに限り利用可",
  "仅限%s": "%sに限り利用可",
  "领取记录": "Rewards History",
  "立即兑换": "確認",
  "规则详情": "規制の詳細",
  "您邀请的好友需通过%s下单，通过其他渠道下单不发放邀请奖励": "招待されたお友達は%sアプリまたはDiDiタクシーアプリのフードタブから注文する必要があります",
  "折": "引き",
  "是，再也不要给我发邮件了": "はい、今後メールを送信しないでください",
  "如果您退订邮件，可能会错过重要消息，您确定不再接收%s给您发送的邮件吗？": "メールの配信を停止すると、重要なメッセージを受け取れない可能性があります。本当に%sからのメール配信を停止しますか？",
  "输入手机号": "携帯電話番号を入力",
  "周六": "土曜日",
  "待入账": "獲得予定",
  "在这里购买！": "Click to Purchase!",
  "阶梯奖励": "特別ボーナス",
  "很遗憾，未能领取成功": "受け取れていません",
  "活动时间": "キャンペーン期間",
  "点击领取": "クーポンを受け取る",
  "落地页": "ランディングページ",
  "C运营弹窗": "DiDi Food運営ポップアップ",
  "隐藏": "非表示",
  "%s可用": "割引対象：%s",
  "POS": "決済機",
  "添加信用卡/借记卡": "クレジットカード/デビットカードを追加",
  "满%s可用": "%s以上で適用",
  "T&C": "Terms and Conditions",
  "活动说明": "キャンペーン詳細",
  "Email": "Email",
  "售罄": "売切れ",
  "当前定位城市：": "現在地：",
  "领取失败": "Não é possível solicitar",
  "网络似乎存在问题，请稍后重试": "ネットワークに問題があるようです。しばらくしてからもう一度お試しください",
  "券包": "クーポンパック",
  "附近没有商家": "近くにお店がありません",
  "分享给朋友，一起享优惠吧！": "Compartilhe para ganhar descontos!",
  "请登录": "ログインしてください",
  "选择城市": "都市を選択",
  "您已经成功领取优惠券": "クーポンの受け取りに成功しました",
  "兑换成功": "引き換えに成功しました",
  "《法律声明": "利用規約",
  "当日有效": "当日限り有効",
  "更多详情": "詳細を見る",
  "有效期至": "有効期限",
  "支付方式": "支払い方法",
  "可获得%s优惠券": "「%s」分のクーポンがもらえる",
  "邀请": "紹介する",
  "等待支付结果": "決済結果照会中…",
  "轻松来获得一个非常棒的优惠券包": "To get a great offer of coupon package",
  "应付金额": "支払金額",
  "请开启定位": "位置情報サービスをオンにしてください",
  "支付超时！": "決済がタイムアウトになりました",
  "奖励会在邀请成功后的48小时内发放入您的账号，您可在%s-钱包-券中查看": "クーポンは招待したお友達が条件達成後、48時間以内にアカウントにお贈りいたします。 %sアプリ → お友達紹介クーポン → クーポン一覧にて確認することができます",
  "购买含4张$10mxn无门槛券的优惠券包仅需支付$20mxn": "Purchase a pack of 4 coupons of $10mxn each for only $20mxn for you to use at your leisure",
  "去升级": "Update",
  "复制成功": "コピーしました",
  "免配送费": "配達料金無料",
  "重新加载": "もう一度読み込む",
  "支付": "購入する",
  "是，一年之内不要给我发邮件": "はい、1年間はメールを送信しないでください",
  "%s日内有效": "%s日以内有効",
  "仅限新用户使用": "新規のお客様に限り利用可",
  "一经发现任何违规、套现行为，将追回相关金额或者封停账号，且依法追究其法律责任": "規約違反や不正行為が発見された場合、割引は適用されず、アカウントの閉鎖や法的責任を追及される場合がございます",
  "有效时间:": "有効時間:",
  "当前无有效活动": "現在キャンペーンはありません",
  "退订": "配信停止",
  "网络不佳，请检查您的网络": "ネットワークエラー。通信状態を確認してください",
  "重试": "再試行",
  "周四": "木曜日",
  "查看更多": "詳細を見る",
  "本优惠活动仅限%s[在线支付]的订单享受，不可提现转赠": "本クーポンは%sの[オンライン決済］注文にのみ適用されます。現金への交換および他人への譲渡はできません",
  "通过SNS邀请": "SNSで招待",
  "分钟": "分",
  "请选择支付方式": "支払方法を選択してください",
  "恭喜你！兑换成功！": "おめでとうございます！引き換えに成功しました！",
  "优惠券中心": "おトクなクーポン一覧",
  "%s 人": "%s 人",
  "我的优惠券": "マイクーポン",
  "我的邀请码": "招待コード",
  "获取优惠券": "クーポンを取得",
  "网络繁忙，请稍后再试": "ネットワークが混み合っています。しばらくしてからもう一度お試しください",
  "我的奖励": "ボーナス一覧",
  "C端落地页": "DiDi Foodランディングページ",
  "兑换失败": "引き換えに失敗しました",
  "很遗憾您无法接收我们的最新消息了，感谢您使用%s！": "今後、最新メッセージを受け取ることができなくなります。%sをご利用いただきありがとうございました！",
  "待入账奖励": "獲得予定",
  "加载失败": "読み込み失敗",
  "必须同意用户条款，才能继续下一步": "次のステップに進むために、ユーザー規約に同意する必要があります。",
  "奖励进度": "ボーナス獲得ステータス",
  "领取成功": "受取成功",
  "附近商家": "近くのお店",
  "暂无优惠券": "クーポンはありません",
  "卡类型": "カードタイプ",
  "格式不正确": "フォーマットが正しくありません",
  "周五": "金曜日",
  "订单": "合計金額",
  "不可用原因": "使用できない理由",
  "请您升级到最新的APP版本，体验外卖功能": "Update to the latest version of the app to start ordering food",
  "周二": "火曜日",
  "邀请一个好友即可获得": "お友達を1人紹介するごとに",
  "券包购买已超限制": "クーポン購入が制限を超えました",
  "返回首页": "トップページへ戻る",
  "与": "and",
  "最高抵扣%s": "割引上限%s",
  "您邀请的好友必须是%s新用户，每人仅限享受1次新用户首单优惠，同一手机号、同一设备、同一支付账号视为同一用户": "招待されたお友達は%sの新規ユーザーであることが必要です。携帯電話番号、デバイス、クレジットカード番号などの決済情報のいずれかが重複している場合、同ーユーザーと見なされます",
  "邮件频率过高": "メールが頻繁すぎる",
  "没有更多了": "以上です",
  "收餐人手机号与领取券时输入的手机号和账号注册手机号必须为同一号码": "商品受取人の携帯電話番号、クーポン受け取り時に入力した携帯電話番号、アカウントの登録携帯電話番号が同一の番号である必要があります",
  "当前手机系统不支持自动复制": "ご利用の携帯電話は自動コピーに対応しません",
  "仅售": "おトクな",
  "去预订食物": "今すぐ注文",
  "More": "More",
  "有效期至%s": "有効期限：%s",
  "兑换优惠券": "クーポンコードを入力",
  "收起": "閉じる",
  "大家都在领取奖励": "Everyone is getting rewards",
  "配送费": "配達料金",
  "去看看": "見る",
  "兑换失败，请再试一次": "引換に失敗しました。もう一度お試しください",
  "确认": "確認",
  "已过期": "期限が切れました",
  "本优惠活动仅限[%s]开通%s区域": "本キャンペーン適用対象は%sの%sサービスエリアとなります",
  "此活动尚未在您的城市开通": "このキャンペーンはお客様の地域でご利用いただけません",
  "确定": "確認",
  "去使用": "利用する",
  "%s": "%s",
  "支付中": "支払う",
  "当天过期": "Expires today",
  "去看看其他的": "もっと見る",
  "在您的订单中使用这些优惠！": "Use them in the orders you choose! Can be used with store promotion!",
  "具体活动规则见": "お客様からいただいた電話番号はクーポン発行にのみ利用します。個人情報の取り扱いについてはこちらをクリックしてご確認ください：",
  "已获得奖励": "獲得済み",
  "条款和条件": "利用規約",
  "SMS": "SMS",
  "配送": "配達",
  "为你的第一单领取优惠": "初回注文特典をゲット",
  "恭喜你获得一张%s优惠券，快去使用吧": "おめでとうございます。%sのクーポンを一枚獲得しました。お早めにご利用ください",
  "复制": "コピー",
  "通用支付": "全ての支払方法",
  "99Pay-余额": "99Pay-残高",
  "我知道了": "わかりました",
  "《法律声明与隐私政策》": "《利用規約およびプライバシーポリシー》",
  "OFF": "OFF",
  "券被领完": "No coupons left",
  "卡": "カード",
  "兑换中": "引換中",
  "优惠券已放入": "クーポンを配布しました",
  "周三": "水曜日",
  "关于本活动的任何问题，请联系%s客服": "本キャンペーンについては、 %sカスタマーセンターにお問い合わせください。メールアドレス：",
  "邀请好友成为%s新用户，好友领取您送出的红包，并在活动时间截止前完成订单且无退款，无论是否使用领取的红包下单，您均可获得%s[优惠券]奖励": "あなたが招待したお友達が下記3点を満たし場合、 あなたは%s［クーポン］を獲得できます。%s新規ユーザー登録 あなたが送ったクーポン受け取る キャンペーン締め切り前に注文完了（クーポン利用有無は関係ありません）",
  "已参与过同类型活动</br>快去下单吧": "同様のキャンペーンに参加したことがあります</br>早速注文しましょう",
  "推荐骑手": "紹介ボーナス",
  "活动已结束": "キャンペーンは終了しました",
  "今日领取次数达上限": "Daily limit reached",
  "看看其他的活动": "他のキャンペーンを見る",
  "周一": "月曜日",
  "隐私政策》": "Privacy Policy",
  "FoodC_birthday_nxQg_feGV": "Remaining uses: {{n}}",
  "FoodC_unsubscription_moTV_YOeR": "We’ll use this information to improve our membership services.",
  "FoodD_optimization_lXdy_Scjt": "Detailed rules",
  "FoodC_update_mXRF_lKCK": "New",
  "FoodC_update_YSxw_ZNzh": "First month",
  "FoodC_shoprank_OYFZ_jaot": "Detailed Rules",
  "FoodC_subscription_uipE_quiy": "Back",
  "FoodC_V2_kGDN_zGcg": "By subscribing, you authorize {{didifoodbrand}} to automatically charge you {{money}} on a monthly basis starting on {{date}}. You can unsubscribe anytime with no additional fees or penalties. {{rules}}",
  "FoodC_update_UDFM_jhpN": "Free delivery on all orders",
  "FoodC_V2_RTeI_mEFs": "Starting from {{date}}, you’ll be automatically charged {{money}} per month. You can cancel your subscription anytime.",
  "FoodC_CGC_Other_channel_oFak": "Share via Other App",
  "FoodC_Notifications_Invite_users_Ovxe": "User(s) invited",
  "FoodC_CGC_SaMA_ymHl": "This event is only for {{app_name}} orders paid online, and rewards cannot be withdrawn or transferred",
  "FoodC_birthday_UhuO_lUDL": "Unable to claim coupons. Please try again later",
  "FoodC_CGC_XBGw_UVvR": "Friends you invite must be eligible to the event rules of {{app_name}}. Each user is entitled to enjoy the reward only {{num}}times. Users with the same mobile number, device or payment account will be considered to be the same user",
  "FoodC_subscription_skWN_HfEE": "Auto Renewal",
  "FoodC_pause_iXVD_owuh": "Your {{brand}} subscription will automatically resume after the pause has ended, and you will be charged {{money}} on {{date}}. {{tc}}",
  "FoodC_unsubscription_tNxS_UdhI": "Please select a reason",
  "FoodC_pause_Wukv_RhXR": "Pause Subscription",
  "FoodC_subscription_StNY_sWmE": "By submitting payment, you agree to the {{rules}}.",
  "FoodB_V2_dLkl_pbiF": "To perform this action, please update to the latest version of the app",
  "FoodC_url_reaV_LXaO": "Use Now",
  "FoodC_update_wBxB_qtoB": "Subscribe to {{brand2}} and enjoy your first month free. You will authorize {{didifoodbrand}} to automatically charge you {{money1}}/mo for the next two months and {{money2}}/mo each month thereafter. You can unsubscribe anytime with no additional fees or penalties. {{rules}}",
  "FoodC_update_VNPd_BxNP": "Free Upgrade to {{brand1}}",
  "FoodC_CGC_qcLp_cjum": "This event is only available in selected areas where the {{app_name}} friends invitation event is offered, including: {{city_list}}",
  "FoodC_c_Please_try_cHSE": "Please try again later",
  "FoodC_pause_izJa_kTkD": "Want to pause your membership subscription?",
  "FoodC_CGC_KVtY_VNSE": "Invite friends to order on {{app_name}}. As long as your friend claims a coupon shared by you and completes an order without requesting a refund before the event ends, you will receive a coupon reward of {{amount}} whether the claimed coupon is used or not",
  "FoodC_subscription_uwdO_LCcw": "Booster Pack",
  "FoodD_optimization_Wiuc_vkvD": "Invite a Friend",
  "FoodC_register_cCpr_KUUG": "Payment Unsuccessful",
  "FoodC_words_vHza_gIgE": "Join {{brand}} for {{money}}/mo",
  "FoodC_C_No_coupon_DlRu": "No coupon packs available",
  "FoodC_words_TYXi_ntMY": "Then {{money}}/mo",
  "FoodC_CGC_WOQY_zerr": "The recipient’s mobile number for receiving orders must be the same as the mobile number used for claiming coupons and account registration.",
  "FoodC_unsubscription_EyKD_bpPY": "Continue using {{brand}}",
  "FoodC_update_KTVU_dCMP": "Unlimited",
  "FoodC_V2_RUXY_aLKJ": "Auto-Pay Method",
  "FoodC_Notifications_Congratulations_you_qoKt": "You earned a reward",
  "FoodC_update_QaEe_FhNn": "{{money}}/mo",
  "FoodC_subscription_CYiq_FQpm": "Price: {{money}}",
  "FoodC_register_qELy_skhp": "Please try another payment method",
  "FoodC_rule_14_Up_dovx": "Limit 2 coupons per customer per day",
  "FoodC_update_PJJg_EEDD": "Next 2 months",
  "FoodC_c_Sign_in_gRWz": "Sign In",
  "FoodC_update_KqNE_JARP": "Use {{brand1}} free for 30 days",
  "FoodC_subscription_gNZb_xfcd": "Unsubscribe from {{didipackbrand}}",
  "FoodC_page_XCBA_oZij": "orders",
  "FoodC_birthday_uoAk_Rmzj": "Tap here to view your coupons",
  "FoodC_unsubscription_xxOV_zoQL": "Why do you want to cancel your subscription?",
  "FoodD_optimization_WVNI_kCwK": "{{day}} day(s) {{hour}} hour(s) left to invite",
  "FoodC_subscription_JZaZ_ToBk": "Only {{money1}} for the first month to join {{didipackbrand}} and subscribe. You will authorize {{didifoodbrand}} to automatically charge you {{money2}} in the following month. You can unsubscribe anytime with no additional fees or penalties.",
  "FoodC_V2_ryoa_JKea": "Add a payment method",
  "FoodC_pause_LiWX_icgn": "Your subscription has been resumed!",
  "FoodC_pause_pfAh_qUrD": "Pause Membership",
  "FoodC_subscription_arhI_yUex": "Keep My Benefits",
  "FoodC_pause_xeiI_PONf": "No Thanks",
  "FoodC_birthday_mwOE_zhiz": "Got It",
  "FoodC_pause_AtFL_nncW": "Subscription Paused",
  "FoodC_didipack_XQlZ_sNjR": "Only {{money1}} for the first month to join {{didipackbrand}} and enable your subscription. You will authorize {{didifoodbrand}} to automatically charge you {{money2}} in the following month. You can unsubscribe anytime with no additional fees or penalties. If you request to unsubscribe after payment for the current month has already been made, your subscription will be cancelled the following month.",
  "FoodC_birthday_bthp_qePu": "Confirm",
  "FoodD_optimization_jCaj_SrWJ": "There are no active reward events in the current city",
  "FoodC_c_Login_for_puGR": "Sign in to view more info",
  "FoodC_pause_cYix_reoR": "Your {{brand}} subscription will be paused on {{date}}. While your subscription is paused, you won’t be charged and won’t receive any member perks.",
  "FoodC_update_ZFGk_VVRa": "View Details",
  "FoodC_CGC_Copy_MtAU": "Copy",
  "FoodC_pause_NJOj_iqnX": "Membership subscription is paused.",
  "FoodC_update_BIRW_NftU": "Use {{brand1}} free for 30 days (Upgrade from {{brand2}})",
  "FoodC_Notifications_Invite_successful_RBjt": "Successful invite(s)",
  "FoodC_V2_RlIh_OuIP": "Payment method",
  "FoodC_page_ZTLT_FASC": "Out of coupons? Get more Free Delivery perks here",
  "FoodC_birthday_teJB_YsTQ": "Birthday: {{date}}",
  "FoodC_birthday_lxme_nZrh": "Set Your Birthday",
  "FoodC_shoprank_Qwab_yWBb": "Got It",
  "FoodC_CGC_Cruel_departure_Smts": "No Thanks",
  "FoodC_register_mezq_UlpA": "Got It",
  "FoodC_words_ikBq_XDxq": "Join {{brand}} for {{money}}",
  "FoodC_update_pgdT_nUsj": "Average savings of {{money}}",
  "FoodC_C_No_coupon_JTJL": "No coupon packs available for purchase",
  "FoodC_CGC_Go_and_fnbj": "Claim Now",
  "FoodC_V2_SvHk_RdVM": "Congrats! You successfully joined {{didipackbrand}}",
  "FoodD_optimization_MkEn_uAUM": "Try switching cities",
  "FoodC_subscription_LiqM_lRQw": "DiDi Club",
  "FoodC_CGC_kRMT_EBEL": "Any friends you invite must order via {{app_name}}. Rewards won’t be issued for orders placed through other channels",
  "FoodC_CGC_QXwb_BKmD": "Event Period: {{startdate}} to {{enddate}}",
  "FoodC_member_qXYs_kKZA": "Got It",
  "FoodC_CGC__name_Mjiz": "{{name}} got a {{x}} discount",
  "FoodC_CGC_Invite_immediately_dQRx": "Send Invite",
  "FoodC_subscription_pODC_SAYm": "Expired",
  "FoodC_Notifications__end_QQPI": "Ends in {{end_time}} days",
  "FoodC_V2_syWI_awMo": "Credit / debit card",
  "FoodC_CGC_gLdR_yRZb": "If any violations or cash-out activities are discovered, the relevant amount will be recovered or the account will be suspended, and legal responsibilities will be pursued in accordance with the law.",
  "FoodC_subscription_YJLt_vjMk": "Join {{didipackbrand}}",
  "FoodC_birthday_JshR_FWPW": "Date cannot be modified once set",
  "FoodB_20230410_YNjt_xAWA": "DiDi Top",
  "FoodC_birthday_ZEpi_KbUs": "Coupons Claimed Successfully",
  "FoodC_pause_VaEr_OWMS": "Subscription Paused",
  "FoodC_didipack_uwFV_TgLK": "Become a member and save {{money}}/month",
  "FoodC_Notifications_Invite_user_GtIH": "Invite {{user_num}} more new user(s) and get {{gift}}",
  "FoodC_words_MBwE_Wmhg": "{{money}} for the first month",
  "FoodC_birthday_hJRO_UaMS": "Claim Birthday Coupons",
  "FoodC_invite_Step_2_AhBV": "Step 2: The new user signs up and places an order",
  "FoodC_subscription_RApp_AMMC": "By purchasing {{didipackbrand}}, you agree to the {{rules}}. You’ll be charged {{money}} on the billing day of every month. You can unsubscribe for free anytime.",
  "FoodC_Notifications_Congratulations_gTCr": "Congratulations",
  "FoodC_CGC_xGpr_jdrf": "If you have any questions about this event, please contact {{app_name}} Customer Service",
  "FoodC_unsubscription_oSsY_xaBg": "Tell us more (Optional)",
  "FoodC_invite_Step_3_VjuY": "Step 3: Earn rewards",
  "FoodC_subscription_CtEg_IEoy": "Unsubscribe from {{didipackbrand}}",
  "FoodC_subscription_yHJx_Ospz": "Validity period: {{date1}} – {{date2}}",
  "FoodC_words_bshC_QpQz": "Join {{brand}} for {{money}} for 1st Month",
  "FoodC_unsubscription_PWOD_DyeO": "You have successfully subscribed!",
  "FoodC_pause_aidJ_DeYv": "Your subscription was cancelled.",
  "FoodC_update_lgoe_CKtm": "Free",
  "FoodC_c_Please_log_pWNK": "Please sign in to your account",
  "FoodC_subscription_eceZ_mHfd": "You’ll be automatically charged {{money}} on {{date}}. You can unsubscribe anytime with no additional fees or penalties.",
  "FoodD_optimization_KHwe_UDpu": "View all",
  "FoodC_didipack_kIYJ_MqHw": "You will authorize {{didifoodbrand}} to automatically charge you {{money2}} in the following month. You can unsubscribe anytime with no additional fees or penalties. If you request to unsubscribe after payment for the current month has already been made, your subscription will be cancelled the following month.",
  "FoodC_invite_Step_1_IIde": "Step 1: Invite a new user",
  "FoodC_c_Unable_to_cPrS": "Service Unavailable",
  "FoodD_optimization_Qqcn_TvQv": "No invites. See History for more",
  "FoodD_optimization_pADD_hRLc": "Other Invite Options",
  "FoodC_member_wEQc_Appv": "You’ve already selected to purchase {{brand}} as part of your current order. Simply complete your order to become a member!",
  "FoodC_Notifications_Rewards_received_czXX": "Rewards earned",
  "FoodC_Notifications_Accept_the_wTgP": "Accepted Invite",
  "FoodC_pause_dEKK_qnxf": "Your membership subscription has been paused, and member perks will be unavailable for {{num}} month(s) starting from {{date1}}. Your subscription to {{brand}} will resume on {{date2}} and you will be automatically charged {{money}}. {{tc}}",
  "FoodC_subscription_KlqY_fMjr": "Valid",
  "FoodC_member_fKsy_UJNP": "View Order",
  "FoodC_birthday_MdbO_KlNx": "Tell us your birthday",
  "FoodD_optimization_rIEQ_UWUa": "Share",
  "FoodC_CGC_There_is_gZQn": "You still have {{x}} to claim",
  "FoodC_member_rIZz_EbdA": "You’re already purchasing a membership",
  "FoodC_CGC_JFyK_TjqE": "Coupon usage limits and validity period are displayed on the coupon. Coupons may only be used during the validity period",
  "FoodC_subscription_XfQl_RWPP": "Subscription",
  "FoodC_c_Try_again_lIZQ": "Try Again",
  "FoodC_unsubscription_qlCR_GqbU": "Cancel Subscription",
  "FoodC_birthday_vcey_tequ": "View Coupons",
  "FoodC_update_FOFJ_UkRW": "5% off all rides",
  "FoodC_page_hbyT_BKde": "Free Delivery",
  "FoodC_subscription_HZKf_jObw": "You currently don’t have an active subscription",
  "FoodD_optimization_hPwx_hGCS": "History",
  "FoodC_subscription_Jnyy_Zokk": "Your {{didipackbrand}} subscription is being renewed. Please check back later",
  "FoodC_V2_NigJ_XaWd": "Subscribe to {{didipackbrand}}",
  "FoodC_update_iSQF_topi": "No service fees on all orders",
  "FoodC_update_rJfl_rGTF": "No Service Fees",
  "FoodC_STORE_sDEb_djsn": "Ranking",
  "FoodC_subscription_Wlpe_bBkf": "History",
  "FoodC_419_zBRR_jLNF": "No minimum order",
  "FoodC_C_No_purchase_cMRq": "No purchase history",
  "FoodC_update_fIEP_oDcY": "Upgrade",
  "FoodC_unsubscription_tnHb_xMuO": "Next",
  "FoodC_update_ZTvS_WSTC": "Free Delivery",
  "FoodC_V2_qWhb_IjVg": "Subscribe",
  "FoodC_update_fyhl_EPiz": "5% Off Rides",
  "FoodC_didipack_ruhC_zsFU": "Join {{didipack}} for {{money}}",
  "FoodC_CGC_PFoL_QAIo": "A coupon can only be used when a single order amount exceeds the coupon threshold amount. Only one coupon may be used per order. Any amount in excess of the discount shall be paid by the user",
  "FoodC_pause_qsCr_nszu": "Resume Subscription",
  "FoodC_CGC_wkuZ_BrqU": "Rewards are issued based on the city in which the [person referred] is located",
  "FoodC_register_WoRy_tjec": "Please select a payment method",
  "Gattaran_reffral_Kjwv_iVSZ": "Invite Friends",
  "FoodC_Notifications_Completed_pEhY": "Completed",
  "FoodC_cash_wnPC_qiCD": "Use Now",
  "FoodC_update_VTVc_ZtNA": "Thereafter",
  "FoodC_subscription_uoAf_TiHz": "Thank you for using {{didipackbrand}}. If you unsubscribe, your {{didipackbrand}} membership will expire on {{date}}. Are you sure you want to unsubscribe?",
  "FoodC_subscription_QaXu_EIIa": "Manage {{didipackbrand}}",
  "FoodC_V2_SSNq_tsZF": "Got It",
  "FoodC_subscription_VPMP_seXT": "Terms and Conditions",
  "FoodC_subscription_jyMF_Ybuq": "You’re currently enjoying a free trial of {{brand}}. If you unsubscribe now, your free trial will expire immediately and you won’t be eligible for Free Delivery on food orders or other member perks.",
  "FoodC_subscription_DzKZ_WhvD": "Date of purchase: {{date}}",
  "FoodC_CGC_has_successfully_CrTW": "{{x}} friends invited",
  "FoodC_update_TDwr_XZVd": "My {{brand2}}",
  "FoodC_CGC_UFif_hfJM": "Rewards will be issued to your account within {{num}} hour(s) after a successful invite. You can view your rewards by going to {{app_name}} > Wallet > Coupons",
  "FoodC_center_Ospi_SNsH": "Totals savings so far",
  "FoodC_page_eUPd_sDHg": "Rules",
  "FoodC_pause_tfPj_IAoM": "Your subscription to {{brand}} will resume on {{date}} and you will be automatically charged {{money}}. {{tc}}"
}