module.exports = {
  "FoodC_C_Detailed_rules_WfnL": "How it works",
  "FoodC_birthday_nxQg_feGV": "Remaining uses: {{n}}",
  "FoodC_unsubscription_moTV_YOeR": "We’ll use this information to improve our membership services.",
  "FoodD_optimization_lXdy_Scjt": "Detailed rules",
  "FoodC_update_mXRF_lKCK": "New",
  "FoodC_update_YSxw_ZNzh": "First month",
  "FoodC_shoprank_OYFZ_jaot": "Detailed Rules",
  "FoodC_subscription_uipE_quiy": "Back",
  "FoodC_V2_kGDN_zGcg": "By subscribing, you authorize {{didifoodbrand}} to automatically charge you {{money}} on a monthly basis starting on {{date}}. You can unsubscribe anytime with no additional fees or penalties. {{rules}}",
  "FoodC_update_UDFM_jhpN": "Free delivery on all orders",
  "FoodC_store_Store_Coupon_szoR": "Store Coupons",
  "FoodC_Task_Congratulations_pDXl": "Congrats!",
  "FoodC_V2_RTeI_mEFs": "Starting from {{date}}, you’ll be automatically charged {{money}} per month. You can cancel your subscription anytime.",
  "FoodC_CGC_Other_channel_oFak": "Share via Other App",
  "FoodC_Notifications_Invite_users_Ovxe": "User(s) invited",
  "FoodC_C_Off_fkZy": "off",
  "FoodC_CGC_SaMA_ymHl": "This event is only for {{app_name}} orders paid online, and rewards cannot be withdrawn or transferred",
  "FoodC_birthday_UhuO_lUDL": "Unable to claim coupons. Please try again later",
  "FoodC_CGC_XBGw_UVvR": "Friends you invite must be eligible to the event rules of {{app_name}}. Each user is entitled to enjoy the reward only {{num}}times. Users with the same mobile number, device or payment account will be considered to be the same user",
  "FoodC_subscription_skWN_HfEE": "Auto Renewal",
  "FoodC_pause_iXVD_owuh": "Your {{brand}} subscription will automatically resume after the pause has ended, and you will be charged {{money}} on {{date}}. {{tc}}",
  "FoodC_unsubscription_tNxS_UdhI": "Please select a reason",
  "FoodC_pause_Wukv_RhXR": "Pause Subscription",
  "FoodC_subscription_StNY_sWmE": "By submitting payment, you agree to the {{rules}}.",
  "FoodB_V2_dLkl_pbiF": "To perform this action, please update to the latest version of the app",
  "FoodC_V1_Gift_box_UWqz": "You already opened this gift",
  "FoodC_C_Mission_completed_FkCw": "Task completed",
  "FoodC_url_reaV_LXaO": "Use Now",
  "FoodC_update_wBxB_qtoB": "Subscribe to {{brand2}} and enjoy your first month free. You will authorize {{didifoodbrand}} to automatically charge you {{money1}}/mo for the next two months and {{money2}}/mo each month thereafter. You can unsubscribe anytime with no additional fees or penalties. {{rules}}",
  "FoodD_Activity_Completed_tPlA": "Completed",
  "FoodC_update_VNPd_BxNP": "Free Upgrade to {{brand1}}",
  "FoodC_CGC_qcLp_cjum": "This event is only available in selected areas where the {{app_name}} friends invitation event is offered, including: {{city_list}}",
  "FoodC_details_The_following_bPLC": "Usable at the following stores",
  "FoodC_c_Please_try_cHSE": "Please try again later",
  "FoodC_C_View_Coupon_OIhv": "View Coupons",
  "FoodC_1_Maximum_number_cJPy": "Daily limit reached",
  "FoodC_C_Received_DOQd": "Claimed",
  "FoodC_pause_izJa_kTkD": "Want to pause your membership subscription?",
  "FoodC_homepage_Unable_to_ybHC": "Location not found",
  "FoodC_CGC_KVtY_VNSE": "Invite friends to order on {{app_name}}. As long as your friend claims a coupon shared by you and completes an order without requesting a refund before the event ends, you will receive a coupon reward of {{amount}} whether the claimed coupon is used or not",
  "FoodC_subscription_uwdO_LCcw": "Booster Pack",
  "FoodD_optimization_Wiuc_vkvD": "Invite a Friend",
  "soda-d-i18n---奖励详情": "Reward Details",
  "FoodC_2_Enter_mobile_jXbU": "Enter mobile number",
  "FoodC_register_cCpr_KUUG": "Payment Unsuccessful",
  "FoodC_words_vHza_gIgE": "Join {{brand}} for {{money}}/mo",
  "FoodC_C_No_coupon_DlRu": "No coupon packs available",
  "FoodC_demand_Courier_purchasing_vPGD": "Shopped by Courier",
  "FoodC_words_TYXi_ntMY": "Then {{money}}/mo",
  "FoodC_CGC_WOQY_zerr": "The recipient’s mobile number for receiving orders must be the same as the mobile number used for claiming coupons and account registration.",
  "FoodC_unsubscription_EyKD_bpPY": "Continue using {{brand}}",
  "FoodC_update_KTVU_dCMP": "Unlimited",
  "soda-d-i18n---没有更多了": "No more results",
  "FoodC_V2_RUXY_aLKJ": "Auto-Pay Method",
  "FoodC_Notifications_Congratulations_you_qoKt": "You earned a reward",
  "FoodC_page_Your_invited_sPpR": "The friend you invite must be a new {{\t\nproductname_didi_food}} customer. First-time order discounts are limited to one per customer (each customer must have a unique phone number, device, and payment account)",
  "FoodC_update_QaEe_FhNn": "{{money}}/mo",
  "FoodC_C_Mission_Centre_HafM": "Task Center",
  "FoodC_subscription_CYiq_FQpm": "Price: {{money}}",
  "FoodC_page_Rewards_are_dWeY": "Reward is issued based on the city in which the [person referred] is located",
  "FoodC_C_Purchased_YheA": "Purchased",
  "FoodC_register_qELy_skhp": "Please try another payment method",
  "FoodC_rule_14_Up_dovx": "Limit 2 coupons per customer per day",
  "FoodC_C_Available_DlSP": "Available",
  "sailing.d-api.common.活动时间": "Event Time",
  "FoodC_update_PJJg_EEDD": "Next 2 months",
  "FoodOpC_city_Rewards_are_oURm": "Reward is issued based on the city in which the [Person Referred] is located",
  "FoodC_C_You_will_FoTj": "You’ll receive",
  "FoodC_page_The_threshold_yZFq": "Coupon usage limits and validity period are displayed on the coupon. Coupons may only be used during the validity period",
  "FoodC_c_Sign_in_gRWz": "Sign In",
  "FoodC_Translation__brand_LAYp": "{{brand_name}} Coupons",
  "FoodC_update_KqNE_JARP": "Use {{brand1}} free for 30 days",
  "FoodC_subscription_gNZb_xfcd": "Unsubscribe from {{didipackbrand}}",
  "FoodC_page_XCBA_oZij": "orders",
  "sailing.c-api-intl.超出配送范围": "Out of delivery boundary",
  "FoodC_birthday_uoAk_Rmzj": "Tap here to view your coupons",
  "FoodC_unsubscription_xxOV_zoQL": "Why do you want to cancel your subscription?",
  "FoodD_2_Single_amount_LbPB": "Orders",
  "FoodD_optimization_WVNI_kCwK": "{{day}} day(s) {{hour}} hour(s) left to invite",
  "FoodC_C_No_suitable_UnyN": "Currently no promotions available",
  "FoodC_subscription_JZaZ_ToBk": "Only {{money1}} for the first month to join {{didipackbrand}} and subscribe. You will authorize {{didifoodbrand}} to automatically charge you {{money2}} in the following month. You can unsubscribe anytime with no additional fees or penalties.",
  "FoodC_V2_ryoa_JKea": "Add a payment method",
  "FoodC_pause_LiWX_icgn": "Your subscription has been resumed!",
  "FoodC_pause_pfAh_qUrD": "Pause Membership",
  "FoodC_C_Purchase_Records_CIUH": "History",
  "FoodC_subscription_arhI_yUex": "Keep My Benefits",
  "FoodC_pause_xeiI_PONf": "No Thanks",
  "FoodC_birthday_mwOE_zhiz": "Got It",
  "FoodC_pause_AtFL_nncW": "Subscription Paused",
  "FoodC_didipack_XQlZ_sNjR": "Only {{money1}} for the first month to join {{didipackbrand}} and enable your subscription. You will authorize {{didifoodbrand}} to automatically charge you {{money2}} in the following month. You can unsubscribe anytime with no additional fees or penalties. If you request to unsubscribe after payment for the current month has already been made, your subscription will be cancelled the following month.",
  "FoodC_birthday_bthp_qePu": "Confirm",
  "FoodD_optimization_jCaj_SrWJ": "There are no active reward events in the current city",
  "FoodC_c_Login_for_puGR": "Sign in to view more info",
  "FoodC_C_Coupon_Purchase_zdkl": "Purchase History",
  "FoodC_pause_cYix_reoR": "Your {{brand}} subscription will be paused on {{date}}. While your subscription is paused, you won’t be charged and won’t receive any member perks.",
  "FoodC_C_Payment_money_XOtx": "Purchase for {{money}}",
  "FoodC_C_Buy_again_ScTm": "Can be purchased again in {{countdown}}",
  "FoodC_button_Download_DUuD": "Download {{product_name}}",
  "FoodC_update_ZFGk_VVRa": "View Details",
  "FoodC_CGC_Copy_MtAU": "Copy",
  "FoodC_pause_NJOj_iqnX": "Membership subscription is paused.",
  "FoodD_Activity_Single_Step_xtWh": "Reward by Order Volume",
  "FoodC_update_BIRW_NftU": "Use {{brand1}} free for 30 days (Upgrade from {{brand2}})",
  "FoodC_Notifications_Invite_successful_RBjt": "Successful invite(s)",
  "FoodC_C_Time_of_LACt": "Time of purchase",
  "FoodC_V2_RlIh_OuIP": "Payment method",
  "FoodC_page_ZTLT_FASC": "Out of coupons? Get more Free Delivery perks here",
  "FoodC_birthday_teJB_YsTQ": "Birthday: {{date}}",
  "FoodC_birthday_lxme_nZrh": "Set Your Birthday",
  "FoodC_C_Go_tdhw": "Order Now",
  "soda-m-i18n---奖励规则": "Reward Rules",
  "FoodC_shoprank_Qwab_yWBb": "Got It",
  "FoodC_CGC_Cruel_departure_Smts": "No Thanks",
  "FoodC_C_Buy_again_iOeQ": "Buy Again in {{day}} Day(s)",
  "FoodC_C_Unlimited_store_Ujro": "Take advantage of this promo and save {{money}} from the delivery fee in your next orders!",
  "FoodC_register_mezq_UlpA": "Got It",
  "FoodC_words_ikBq_XDxq": "Join {{brand}} for {{money}}",
  "FoodD_Activity_In_progress_NrnP": "Active",
  "FoodC_1_Coupon_received_EEkp": "No coupons left",
  "FoodC_update_pgdT_nUsj": "Average savings of {{money}}",
  "FoodC_C_No_coupon_JTJL": "No coupon packs available for purchase",
  "FoodC_CGC_Go_and_fnbj": "Claim Now",
  "FoodC_V2_SvHk_RdVM": "Congrats! You successfully joined {{didipackbrand}}",
  "FoodD_optimization_MkEn_uAUM": "Try switching cities",
  "FoodC_Task_Successfully_received_jXDz": "You received {{coupon}} coupon(s).",
  "FoodC_1_Coupon_Account_kcLB": "Current account: {{phone_number}}",
  "DiDi_Food_(C)_Suspended_Delivery_status_Delivery_Pause_PIPS": "Temporarily Unavailable",
  "FoodC_C_Collection_rqwP": "Claim",
  "FoodC_subscription_LiqM_lRQw": "DiDi Club",
  "FoodC_V1_Click_receive_fBbu": "Claim Now",
  "FoodC_CGC_kRMT_EBEL": "Any friends you invite must order via {{app_name}}. Rewards won’t be issued for orders placed through other channels",
  "FoodC_C_Universal_Coupon_qLGm": "Universal Coupon",
  "FoodC_C_Used_tgaq": "Used",
  "FoodC_C_Only_money_ZEKz": "Pay Only {{money}}",
  "FoodC_CGC_QXwb_BKmD": "Event Period: {{startdate}} to {{enddate}}",
  "FoodC_member_qXYs_kKZA": "Got It",
  "FoodC_C_Expired_AEpg": "Expired",
  "FoodB_Part2_Detailed_description_mxuF": "Details",
  "FoodC_CGC__name_Mjiz": "{{name}} got a {{x}} discount",
  "FoodC_CGC_Invite_immediately_dQRx": "Send Invite",
  "FoodB_supplement_Network_connection_RVcl": "Connection failed. Please check your network settings",
  "FoodC_page_Rewards_will_NhMd": "Your reward will be added to your account within 48 hours of a successful referral. To view rewards, go to {{\t\nproductname_didi_food}} > Wallet > Coupons",
  "FoodC_subscription_pODC_SAYm": "Expired",
  "FoodC_Notifications__end_QQPI": "Ends in {{end_time}} days",
  "FoodC_page_Upon_discovery_baTq": "Coupon may not be exchanged or redeemed for cash. A violation of the coupon rules may result in the recovery of the relevant amount and account suspension, and may be punishable by law",
  "FoodC_C_To_be_Qemy": "Available",
  "FoodC_V2_syWI_awMo": "Credit / debit card",
  "FoodC_CGC_gLdR_yRZb": "If any violations or cash-out activities are discovered, the relevant amount will be recovered or the account will be suspended, and legal responsibilities will be pursued in accordance with the law.",
  "FoodC_button_Open_fmKT": "Open {{product_name}}",
  "FoodD_Activity_Rewards_will_TMCp": "Rewards are calculated after the event ends",
  "FoodC_subscription_YJLt_vjMk": "Join {{didipackbrand}}",
  "FoodC_birthday_JshR_FWPW": "Date cannot be modified once set",
  "FoodB_20230410_YNjt_xAWA": "DiDi Top",
  "FoodC_birthday_ZEpi_KbUs": "Coupons Claimed Successfully",
  "FoodC_pause_VaEr_OWMS": "Subscription Paused",
  "FoodC_didipack_uwFV_TgLK": "Become a member and save {{money}}/month",
  "FoodC_coupons_Congratulations_on_YevG": "Congrats! You received a coupon",
  "FoodC_Notifications_Invite_user_GtIH": "Invite {{user_num}} more new user(s) and get {{gift}}",
  "FoodC_words_MBwE_Wmhg": "{{money}} for the first month",
  "FoodC_birthday_hJRO_UaMS": "Claim Birthday Coupons",
  "soda-b-web-i18n---休息中": "Close",
  "FoodC_invite_Step_2_AhBV": "Step 2: The new user signs up and places an order",
  "FoodC_subscription_RApp_AMMC": "By purchasing {{didipackbrand}}, you agree to the {{rules}}. You’ll be charged {{money}} on the billing day of every month. You can unsubscribe for free anytime.",
  "FoodC_Notifications_Congratulations_gTCr": "Congratulations",
  "sailing.banner-intl.暂停营业": "Temporarily closed",
  "FoodC_CGC_xGpr_jdrf": "If you have any questions about this event, please contact {{app_name}} Customer Service",
  "FoodC_1_Receiving_Records_RumV": "Rewards History",
  "FoodC_unsubscription_oSsY_xaBg": "Tell us more (Optional)",
  "FoodC_page_Invite_friends_oGuj": "Invite a friend to try {{\t\nproductname_didi_food}}. If they claim the free coupon you send and complete an order without any refunds within 14 days of receiving your invitation and before the event ends, even if they don’t use the free coupon on their order, you’ll receive a {{\t\nproductname_didi_food}} [coupon]",
  "FoodC_invite_Step_3_VjuY": "Step 3: Earn rewards",
  "FoodC_subscription_CtEg_IEoy": "Unsubscribe from {{didipackbrand}}",
  "FoodC_C_You_will_pQqU": "You’ll receive {{n}} coupons",
  "FoodC_subscription_yHJx_Ospz": "Validity period: {{date1}} – {{date2}}",
  "FoodC_C_Coupon_Center_WghP": "Coupon Pack Center",
  "FoodC_1_Unable_to_yqfz": "Unable to claim",
  "FoodC_V1_Use_IuaW": "Use Now",
  "FoodC_page_This_special_fYuo": "This promo is only offered in areas of {{\t\nactivity_city}} where {{\t\nproductname_didi_food}} is available",
  "FoodC_page_For_any_IWNZ": "For any questions about this promo, please contact Customer Service at {{contact_email}}",
  "FoodD_Activity_Expand_more_VNNc": "Show more",
  "FoodC_words_bshC_QpQz": "Join {{brand}} for {{money}} for 1st Month",
  "FoodC_unsubscription_PWOD_DyeO": "You have successfully subscribed!",
  "FoodC_C_Terms_and_eAhU": "Terms and Conditions",
  "FoodC_pause_aidJ_DeYv": "Your subscription was cancelled.",
  "FoodC_update_lgoe_CKtm": "Free",
  "FoodC_C_Invitation_CPGR": "Invite",
  "FoodC_c_Please_log_pWNK": "Please sign in to your account",
  "FoodC_subscription_eceZ_mHfd": "You’ll be automatically charged {{money}} on {{date}}. You can unsubscribe anytime with no additional fees or penalties.",
  "FoodD_optimization_KHwe_UDpu": "View all",
  "FoodC_didipack_kIYJ_MqHw": "You will authorize {{didifoodbrand}} to automatically charge you {{money2}} in the following month. You can unsubscribe anytime with no additional fees or penalties. If you request to unsubscribe after payment for the current month has already been made, your subscription will be cancelled the following month.",
  "FoodC_invite_Step_1_IIde": "Step 1: Invite a new user",
  "FoodC_V1_View_activity_NCOP": "View Other Events",
  "FoodD_Activity_Number_of_vXPs": "Reward by Persons Referred",
  "FoodC_c_Unable_to_cPrS": "Service Unavailable",
  "FoodC_C_Back_Home_opQY": "Go to Home Page",
  "FoodD_optimization_Qqcn_TvQv": "No invites. See History for more",
  "FoodD_optimization_pADD_hRLc": "Other Invite Options",
  "FoodC_copyaddJP_Your_mobile_zrXT": "Don’t worry, your number will only be used to claim your event coupon",
  "FoodC_member_wEQc_Appv": "You’ve already selected to purchase {{brand}} as part of your current order. Simply complete your order to become a member!",
  "FoodC_Notifications_Rewards_received_czXX": "Rewards earned",
  "soda-m-i18n---完成": "Complete",
  "FoodC_all_You_haven_lSmt": "You haven’t invited any friends yet. Try inviting someone",
  "FoodC_Notifications_Accept_the_wTgP": "Accepted Invite",
  "FoodC_pause_dEKK_qnxf": "Your membership subscription has been paused, and member perks will be unavailable for {{num}} month(s) starting from {{date1}}. Your subscription to {{brand}} will resume on {{date2}} and you will be automatically charged {{money}}. {{tc}}",
  "FoodC_subscription_KlqY_fMjr": "Valid",
  "FoodC_member_fKsy_UJNP": "View Order",
  "FoodC_C_Use_jLVF": "Use",
  "FoodC_birthday_MdbO_KlNx": "Tell us your birthday",
  "FoodC_C__n_tBvE": "{{n}} Coupons",
  "FoodD_optimization_rIEQ_UWUa": "Share",
  "FoodC_C_Maximum_deduction_UvNt": "Up to {{money}} off",
  "FoodC_CGC_There_is_gZQn": "You still have {{x}} to claim",
  "FoodC_member_rIZz_EbdA": "You’re already purchasing a membership",
  "FoodC_CGC_JFyK_TjqE": "Coupon usage limits and validity period are displayed on the coupon. Coupons may only be used during the validity period",
  "FoodC_subscription_XfQl_RWPP": "Subscription",
  "FoodC_c_Try_again_lIZQ": "Try Again",
  "FoodC_unsubscription_qlCR_GqbU": "Cancel Subscription",
  "FoodC_birthday_vcey_tequ": "View Coupons",
  "FoodC_update_FOFJ_UkRW": "5% off all rides",
  "FoodC_page_hbyT_BKde": "Free Delivery",
  "FoodC_C_Coupon_package_aeUS": "Coupon Pack",
  "FoodC_subscription_HZKf_jObw": "You currently don’t have an active subscription",
  "FoodD_optimization_hPwx_hGCS": "History",
  "FoodC_subscription_Jnyy_Zokk": "Your {{didipackbrand}} subscription is being renewed. Please check back later",
  "soda-m-i18n---取消": "Cancel",
  "FoodC_C_More_TIyU": "More",
  "FoodC_1_Additional_Surprise_bBNh": "New customers enjoy extra rewards",
  "FoodC_C_Up_to_boqc": "--",
  "FoodC_V2_NigJ_XaWd": "Subscribe to {{didipackbrand}}",
  "FoodC_update_iSQF_topi": "No service fees on all orders",
  "FoodC_update_rJfl_rGTF": "No Service Fees",
  "FoodC_STORE_sDEb_djsn": "Ranking",
  "FoodC_subscription_Wlpe_bBkf": "History",
  "sailing.act-api-intl.活动已经结束": "Event has ended",
  "FoodC_419_zBRR_jLNF": "No minimum order",
  "FoodC_C_No_purchase_cMRq": "No purchase history",
  "FoodC_C_Full_money_oqcE": "Available over {{money}}",
  "FoodC_update_fIEP_oDcY": "Upgrade",
  "FoodC_unsubscription_tnHb_xMuO": "Next",
  "FoodC_C_You_have_EjAA": "You received",
  "FoodC_update_ZTvS_WSTC": "Free Delivery",
  "FoodD_Activity_No_activity_fhqd": "No rewards history",
  "FoodC_V2_qWhb_IjVg": "Subscribe",
  "FoodC_update_fyhl_EPiz": "5% Off Rides",
  "FoodC_didipack_ruhC_zsFU": "Join {{didipack}} for {{money}}",
  "FoodC_CGC_PFoL_QAIo": "A coupon can only be used when a single order amount exceeds the coupon threshold amount. Only one coupon may be used per order. Any amount in excess of the discount shall be paid by the user",
  "FoodC_pause_qsCr_nszu": "Resume Subscription",
  "FoodC_CGC_wkuZ_BrqU": "Rewards are issued based on the city in which the [person referred] is located",
  "FoodC_register_WoRy_tjec": "Please select a payment method",
  "Gattaran_reffral_Kjwv_iVSZ": "Invite Friends",
  "FoodC_Notifications_Completed_pEhY": "Completed",
  "FoodC_cash_wnPC_qiCD": "Use Now",
  "FoodC_update_VTVc_ZtNA": "Thereafter",
  "FoodC_C_Purchase_amount_RXkR": "Purchase amount",
  "FoodC_subscription_uoAf_TiHz": "Thank you for using {{didipackbrand}}. If you unsubscribe, your {{didipackbrand}} membership will expire on {{date}}. Are you sure you want to unsubscribe?",
  "FoodC_C_Mission_expired_vxOU": "There are currently no tasks available",
  "FoodC_subscription_QaXu_EIIa": "Manage {{didipackbrand}}",
  "FoodC_C_My_coupon_fmaV": "My Coupons",
  "FoodC_V2_SSNq_tsZF": "Got It",
  "FoodC_subscription_VPMP_seXT": "Terms and Conditions",
  "FoodC_subscription_jyMF_Ybuq": "You’re currently enjoying a free trial of {{brand}}. If you unsubscribe now, your free trial will expire immediately and you won’t be eligible for Free Delivery on food orders or other member perks.",
  "FoodC_C_Order_assignments_xxGZ": "Order Task",
  "FoodC_subscription_DzKZ_WhvD": "Date of purchase: {{date}}",
  "FoodC_1_Share_it_scKc": "Share with friends to enjoy discounts!",
  "FoodD_Activity_Single_reward_ZrZS": "Reward by Order Volume",
  "FoodC_CGC_has_successfully_CrTW": "{{x}} friends invited",
  "FoodC_update_TDwr_XZVd": "My {{brand2}}",
  "FoodC_all_No_reward_ocGJ": "No Rewards",
  "FoodC_1_Everyone_is_Tdgu": "Everyone is getting rewards",
  "FoodC_CGC_UFif_hfJM": "Rewards will be issued to your account within {{num}} hour(s) after a successful invite. You can view your rewards by going to {{app_name}} > Wallet > Coupons",
  "FoodC_C_Go_VWzj": "Order Now",
  "FoodC_toast__SVYJ": "Offer claimed",
  "FoodC_C_The_end_yvSK": "Ends in",
  "FoodC_center_Ospi_SNsH": "Totals savings so far",
  "FoodC_page_eUPd_sDHg": "Rules",
  "FoodD_Activity_Number_of_OgKt": "Reward by Persons Referred",
  "FoodC_pause_tfPj_IAoM": "Your subscription to {{brand}} will resume on {{date}} and you will be automatically charged {{money}}. {{tc}}",
  "soda-d-i18n---无任何记录": "Empty",
  "FoodC_C_Bonded_package_lroO": "Coupon Pack Store",
  "当前无活动": "Currently no events",
  "该城市暂无活动": "Currently no events in this city",
  "取消": "Cancel",
  "网络异常，请稍后再试": "Network error, try again later",
  "手机号码错误": "Invalid mobile number",
  "选择国家或地区": "Select country or region",
  "暂时不需要%s的服务": "I am currently not using %s's service",
  "支付成功": "Payment Successful",
  "我们正在努力": "Working on it",
  "是，半年之内不要给我发邮件": "I’m sure, don’t send me emails for the next six months",
  "将优惠分享到": "Share Promotion to",
  "邮件退订结果": "E-mail Unsubscribe Result",
  "暂无活动": "Currently no events",
  "推荐记录": "Referral History",
  "恭喜你获得%s张优惠券，快去使用吧": "Congrats! You have %s coupons. Use them to order now",
  "X推D": "X-D Referral",
  "X推C": "Customer Referred by X",
  "不支持现金支付": "Cash not accepted",
  "该浏览器不支持自动复制": "Automatic copying is not supported by this browser",
  "对此类邮件不感兴趣": "I am not interested in these kinds of e-mails",
  "退订理由：": "Reason for Unsubscribing:",
  "登录": "Sign In",
  "输入手机号码获取优惠券": "Type your phone number and get coupons!",
  "现金": "Cash",
  "仅限%s": "%s Only",
  "规则详情": "Detailed Rules",
  "您邀请的好友需通过%s下单，通过其他渠道下单不发放邀请奖励": "The friend you invite must make an order via the %s. Orders made through other channels will not fulfill the requirement to receive an invite reward",
  "折": "OFF",
  "是，再也不要给我发邮件了": "I’m sure, don't send me any emails in the future",
  "输入手机号": "Enter mobile number",
  "在这里购买！": "Click to Purchase!",
  "阶梯奖励": "Ladder Rewards",
  "很遗憾，未能领取成功": "Sorry! Unable to claim this promo",
  "活动时间": "Promotion Time",
  "隐藏": "Hide",
  "%s可用": "Available in %s",
  "满%s可用": "over %s",
  "T&C": "Terms and Conditions",
  "售罄": "SOLD OUT",
  "当前定位城市：": "Current city set：",
  "券包": "Coupon Pack",
  "附近没有商家": "No nearby stores",
  "请登录": "Please login",
  "选择城市": "Select city",
  "您已经成功领取优惠券": "Coupon successfully received",
  "兑换成功": "Redeemed",
  "更多详情": "More Details",
  "可获得%s优惠券": "Get %s Coupons",
  "轻松来获得一个非常棒的优惠券包": "To get a great offer of a coupon pack",
  "请开启定位": "Please turn on location service",
  "支付超时！": "Paid Timeout",
  "奖励会在邀请成功后的48小时内发放入您的账号，您可在%s-钱包-券中查看": "Rewards will be sent to your account within 48 hours after a successful invite. To view them, open your %s > Wallet > Coupons",
  "去升级": "Update",
  "复制成功": "Copied",
  "免配送费": "Free Delivery",
  "重新加载": "Reload",
  "支付": "Pay",
  "%s日内有效": "Valid for %s days",
  "有效时间:": "valid until:",
  "当前无有效活动": "No active promotions ",
  "周四": "Thursday",
  "本优惠活动仅限%s[在线支付]的订单享受，不可提现转赠": "This promotion is only available for %s [online payment] orders. It cannot be transferred or redeemed for cash",
  "通过SNS邀请": "Share via Social Media",
  "分钟": "Min",
  "恭喜你！兑换成功！": "Congrats! Coupon redeemed!",
  "优惠券中心": "Coupon Center",
  "我的优惠券": "My Coupons",
  "网络繁忙，请稍后再试": "Network busy, try again later",
  "很遗憾您无法接收我们的最新消息了，感谢您使用%s！": "We're sorry you won't be receiving our latest messages. Thank you for using %s!",
  "奖励进度": "Reward Progress",
  "领取成功": "Claimed!",
  "周五": "Friday",
  "周二": "Tuesday",
  "券包购买已超限制": "Coupon purchase has exceeded the limit",
  "与": "and",
  "最高抵扣%s": "Applicable to %s",
  "您邀请的好友必须是%s新用户，每人仅限享受1次新用户首单优惠，同一手机号、同一设备、同一支付账号视为同一用户": "The friend you invite must be a new %s customer. Each person can only get a “New Customer First Order Discount” one time. The same phone number, same device, and same payment account are all considered to be the same customer",
  "邮件频率过高": "E-mails are sent too frequently",
  "收餐人手机号与领取券时输入的手机号和账号注册手机号必须为同一号码": "The phone number of the order recipient, the number entered upon claiming the coupon, and the number used to register the account must be the same ",
  "当前手机系统不支持自动复制": "Current mobile system does not support auto copy",
  "仅售": "Only",
  "兑换优惠券": "Redeem Coupon",
  "收起": "Tuck up",
  "大家都在领取奖励": "Everyone is getting rewards",
  "配送费": "Delivery Fee",
  "支付中": "Pay",
  "在您的订单中使用这些优惠！": "Use them in the orders you choose! Can be used with store promotion!",
  "已获得奖励": "Earned Rewards",
  "SMS": "SMS",
  "恭喜你获得一张%s优惠券，快去使用吧": "Congrats! You have a %s coupon. Use it to order now",
  "复制": "Copy",
  "我知道了": "I know",
  "《法律声明与隐私政策》": "Terms & Conditions and Privacy Policy ",
  "OFF": "OFF",
  "兑换中": "In progress",
  "周三": "Wednesday",
  "已参与过同类型活动</br>快去下单吧": "You have participated in a similar promotion</br>Go ahead and order!",
  "看看其他的活动": "Look at other activities",
  "周一": "Monday",
  "隐私政策》": "Privacy Policy",
  "推荐有奖": "Referral Rewards",
  "同意": "I Agree",
  "周日": "Sunday",
  "在购买后的一个月内都可以使用": "Available for use within one month after purchase",
  "优惠券使用门槛及有效期详见券面提示，未在券有效期限内使用完毕的，不可继续使用": "Coupon usage and validity period details are displayed on the coupon. Coupons cannot be used after the period of validity ",
  "邮件退订": "Unsubscribe from E-mails",
  "输入兑换码": "Enter promotion code",
  "详细规则": "View Event Rules",
  "Share": "Share",
  "你已经打开过这个礼盒了": "You already opened this gift",
  "知道了": "Later",
  "运营活动": "Operations Promotion",
  "定位服务未开启，需要先开启定位服务才能参与活动！": "You can only participate the event with location service on",
  "购买": "Buy",
  "券账号：%s": "Current account: %s",
  "查看其它活动": "View Other Events",
  "新用户额外惊喜奖励": "New customers enjoy extra rewards",
  "%s天后过期": "Expires in %s days",
  "单笔订单金额满足优惠券使用门槛金额时方可使用，单笔订单只能使用一张优惠券，使用优惠后，如订单金额超出部分需用户自行支付": "A coupon can be used when an order meets the coupon's usage threshold amount. Only one coupon can be used per order. Any amount in excess of the coupon's value must be paid by the customer",
  "可抵扣%s": "Applicable to %s",
  "仅限%s使用": "%s Only",
  "领取记录": "Rewards History",
  "立即兑换": "Redeem Now",
  "如果您退订邮件，可能会错过重要消息，您确定不再接收%s给您发送的邮件吗？": "By unsubscribing, you may miss out on important messages. Are you sure you want to stop receiving these emails?",
  "周六": "Saturday",
  "待入账": "Pending",
  "点击领取": "Claim Now",
  "落地页": "Landing Page",
  "C运营弹窗": "C Operations Pop-up Window",
  "POS": "POS Terminal",
  "添加信用卡/借记卡": "Add credit/debit card",
  "活动说明": "Event Descriptions",
  "Email": "Email",
  "领取失败": "Unable to claim",
  "网络似乎存在问题，请稍后重试": "There seems to be a network issue, please try again later",
  "分享给朋友，一起享优惠吧！": "Share with friends to enjoy discounts!",
  "《法律声明": "Terms & Conditions",
  "当日有效": "Valid on the day",
  "有效期至": "valid until",
  "支付方式": "Payment",
  "邀请": "Invite",
  "等待支付结果": "Waiting for payment result...",
  "应付金额": "Amounts payable",
  "购买含4张$10mxn无门槛券的优惠券包仅需支付$20mxn": "Purchase a pack of 4 coupons of $10mxn each for only $20mxn for you to use at your leisure",
  "是，一年之内不要给我发邮件": "I’m sure, don't send me emails for the next 12 months",
  "仅限新用户使用": "New Customer Only",
  "一经发现任何违规、套现行为，将追回相关金额或者封停账号，且依法追究其法律责任": "Any rules violation or attempt to cash in a coupon may result in a deduction of the amount in question or account suspension. Those in violation may also be held legally accountable",
  "退订": "Unsubscribe",
  "网络不佳，请检查您的网络": "Poor connection. Please check your network connection",
  "重试": "Try again",
  "查看更多": "View",
  "请选择支付方式": "Please select a payment method",
  "%s 人": "%s Person",
  "我的邀请码": "My Unique Referral Code",
  "获取优惠券": "Get Coupons",
  "我的奖励": "My Rewards",
  "C端落地页": "DiDi Food Landing Page",
  "兑换失败": "Unable to redeem",
  "待入账奖励": "Pending Rewards",
  "加载失败": "Unable to load",
  "必须同意用户条款，才能继续下一步": "Please agree to Terms & Conditions and Privacy Policy to use apps",
  "附近商家": "Nearby stores",
  "暂无优惠券": "No coupons available",
  "卡类型": "Card Type",
  "格式不正确": "Incorrect format",
  "订单": "Order",
  "不可用原因": "Invalid reason",
  "请您升级到最新的APP版本，体验外卖功能": "Update to the latest version of the app to start ordering food",
  "邀请一个好友即可获得": "Invite a friend to get coupons",
  "返回首页": "Back to Home",
  "没有更多了": "End of results",
  "去预订食物": "Place an order now",
  "More": "More",
  "有效期至%s": "Valid Until %s",
  "去看看": "Back to Home",
  "兑换失败，请再试一次": "Failed. Try again",
  "确认": "Confirm",
  "已过期": "Expired",
  "本优惠活动仅限[%s]开通%s区域": "This promotion is only available in areas in [%s] where %s is available",
  "此活动尚未在您的城市开通": "Event not yet available in your city",
  "确定": "OK",
  "去使用": "Use Now",
  "%s": "%s",
  "当天过期": "Expires today",
  "去看看其他的": "check out our other promotions",
  "具体活动规则见": "Click the Terms and Conditions  to view the detailed activity rules",
  "条款和条件": "Terms & Conditions",
  "配送": "Delivery",
  "为你的第一单领取优惠": "Receive discounts for your first order!",
  "通用支付": "All Payments",
  "99Pay-余额": "99Pay - Balance",
  "券被领完": "No coupons left",
  "卡": "Card",
  "优惠券已放入": "Coupon sent",
  "关于本活动的任何问题，请联系%s客服": "For any questions regarding this promotion, contact %s customer service at ",
  "邀请好友成为%s新用户，好友领取您送出的红包，并在活动时间截止前完成订单且无退款，无论是否使用领取的红包下单，您均可获得%s[优惠券]奖励": "Invite a friend to become a new %s customer so they can claim a coupon sent from you. If the friend makes an order before the promotion deadline and does not refund the order, no matter if they use your coupon to place the order, you may receive %s [Coupons] Rewards",
  "推荐骑手": "Refer Couriers",
  "活动已结束": "This event has ended",
  "今日领取次数达上限": "Daily limit reached"
}