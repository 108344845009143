module.exports = {
  "FoodC_Notifications__end_QQPI": "Termina em {{end_time}} dias",
  "FoodC_C_Detailed_rules_WfnL": "Como funciona",
  "FoodC_page_Upon_discovery_baTq": "O cupom não pode ser trocado ou resgatado em dinheiro. A violação das regras do cupons pode resultar na recuperação do valor e na suspensão do perfil",
  "FoodC_C_To_be_Qemy": "Disponível",
  "FoodC_button_Open_fmKT": "Abrir {{product_name}}",
  "FoodC_store_Store_Coupon_szoR": "Cupons da loja",
  "FoodC_Task_Congratulations_pDXl": "Parabéns!",
  "FoodD_Activity_Rewards_will_TMCp": "As recompensas são calculadas após o término do evento",
  "FoodC_CGC_Other_channel_oFak": "Compartilhar por outro aplicativo",
  "FoodC_coupons_Congratulations_on_YevG": "Parabéns! Você recebeu um cupom",
  "FoodC_Notifications_Invite_user_GtIH": "Convide mais {{user_num}} usuários e receba {{gift}}",
  "FoodC_Notifications_Invite_users_Ovxe": "Usuários convidados",
  "FoodC_C_Off_fkZy": "OFF",
  "soda-b-web-i18n---休息中": "Fechar",
  "FoodC_Notifications_Congratulations_gTCr": "Parabéns!",
  "sailing.banner-intl.暂停营业": "Temporariamente encerrada",
  "FoodC_1_Receiving_Records_RumV": "Histórico de recompensa",
  "FoodC_page_Invite_friends_oGuj": "Convide um amigo para experimentar a {{\t\nproductname_didi_food}}. Você também receberá um [cupom] de{{\t\nproductname_didi_food}} caso seu amigo faça um pedido completo, sem pedido de reembolso, no prazo de até 14 dias após o recebimento do convite e antes do cupom expirar, mesmo se o cupom não for utilizado no pedido",
  "FoodC_C_You_will_pQqU": "Você receberá {{n}} cupons",
  "FoodC_C_Coupon_Center_WghP": "Central de pacote de cupons",
  "FoodC_V1_Gift_box_UWqz": "Você já abriu esse presente",
  "FoodC_C_Mission_completed_FkCw": "Tarefa concluída",
  "FoodC_1_Unable_to_yqfz": "Não é possível solicitar",
  "FoodC_V1_Use_IuaW": "Usar agora",
  "FoodC_page_This_special_fYuo": "Essa promoção é oferecida somente em áreas de {{\t\nactivity_city}} onde {{\t\nproductname_didi_food}} está disponível.",
  "FoodD_Activity_Completed_tPlA": "Concluído",
  "FoodC_page_For_any_IWNZ": "Para esclarecer dúvidas sobre essa promoção, entre em contato com o Atendimento ao Cliente em {{contact_email}}",
  "FoodD_Activity_Expand_more_VNNc": "Mostrar mais",
  "FoodC_details_The_following_bPLC": "Disponível nas seguintes lojas",
  "FoodC_c_Please_try_cHSE": "Tente novamente mais tarde",
  "FoodC_C_View_Coupon_OIhv": "Ver cupons",
  "FoodC_C_Terms_and_eAhU": "Termos e Condições",
  "FoodC_1_Maximum_number_cJPy": "Limite diário atingido",
  "FoodC_C_Received_DOQd": "Resgatado",
  "FoodC_C_Invitation_CPGR": "Convidar",
  "FoodC_homepage_Unable_to_ybHC": "Localização não encontrada",
  "FoodC_c_Please_log_pWNK": "Entre em seu perfil",
  "FoodC_V1_View_activity_NCOP": "Ver outros eventos",
  "soda-d-i18n---奖励详情": "Detalhes da recompensa",
  "FoodC_2_Enter_mobile_jXbU": "Inserir número de telefone",
  "FoodC_C_No_coupon_DlRu": "Não há pacotes de cupons disponíveis",
  "FoodD_Activity_Number_of_vXPs": "Recompensa por pessoas indicadas",
  "FoodC_demand_Courier_purchasing_vPGD": "Compra: parceiro",
  "FoodC_c_Unable_to_cPrS": "Serviço indisponível",
  "FoodC_C_Back_Home_opQY": "Ir para o início",
  "FoodC_copyaddJP_Your_mobile_zrXT": "Não se preocupe, seu número só será usado no resgate do seu cupom do evento. Ao colocá-lo, você permite que a 99Food envie o resgate do cupom para o seu número.",
  "soda-d-i18n---没有更多了": "Sem mais resultados",
  "FoodC_Notifications_Congratulations_you_qoKt": "Você recebeu uma recompensa",
  "FoodC_page_Your_invited_sPpR": "O amigo que você convidou deve ser um cliente novo da {{\t\nproductname_didi_food}}. Descontos no primeiro pedido são limitados a um por cliente (cada cliente deve ter um único número de telefone, dispositivo e conta para pagamento)",
  "FoodC_Notifications_Rewards_received_czXX": "Recompensas recebidas",
  "FoodC_C_Mission_Centre_HafM": "Central de tarefas",
  "FoodC_page_Rewards_are_dWeY": "A recompensa é emitida com base na cidade em que a [pessoa indicada] está localizada",
  "FoodC_C_Purchased_YheA": "Comprado",
  "soda-m-i18n---完成": "Concluído",
  "FoodC_all_You_haven_lSmt": "Você ainda não convidou nenhum amigo. Convide alguém",
  "FoodC_Notifications_Accept_the_wTgP": "Convite aceito",
  "FoodC_C_Available_DlSP": "Disponível",
  "sailing.d-api.common.活动时间": "Horário do evento",
  "FoodOpC_city_Rewards_are_oURm": "A recompensa é emitida com base na cidade em que a [Pessoa Indicada] está localizada",
  "FoodC_C_You_will_FoTj": "Você receberá",
  "FoodC_page_The_threshold_yZFq": "Os limites de uso e os períodos de validade são exibidos nos cupons. Os cupons só podem ser usados durante o período de validade",
  "FoodC_c_Sign_in_gRWz": "Entrar",
  "FoodC_Translation__brand_LAYp": "Cupons {{brand_name}}",
  "FoodC_C_Use_jLVF": "Usar",
  "FoodC_C__n_tBvE": "Cupons da {{n}}",
  "FoodC_C_Maximum_deduction_UvNt": "Até {{money}} OFF",
  "FoodC_CGC_There_is_gZQn": "Você ainda tem {{x}} para resgatar",
  "FoodC_page_XCBA_oZij": "pedidos",
  "sailing.c-api-intl.超出配送范围": "Fora da área de entrega",
  "FoodC_c_Try_again_lIZQ": "Tentar novamente",
  "FoodD_2_Single_amount_LbPB": "Pedidos",
  "FoodC_page_hbyT_BKde": "Entrega gratuita",
  "FoodC_C_No_suitable_UnyN": "No momento não há ofertas disponíveis",
  "FoodC_C_Coupon_package_aeUS": "Pacote de cupons",
  "soda-m-i18n---取消": "Cancelar",
  "FoodC_C_Purchase_Records_CIUH": "Histórico",
  "FoodC_C_More_TIyU": "Mais",
  "FoodC_1_Additional_Surprise_bBNh": "Novos clientes ganham recompensas extras",
  "FoodC_C_Up_to_boqc": "--",
  "sailing.act-api-intl.活动已经结束": "Ação encerrada",
  "FoodC_419_zBRR_jLNF": "Sem pedido mínimo",
  "FoodC_C_No_purchase_cMRq": "Sem histórico de compras",
  "FoodC_C_Full_money_oqcE": "Pedidos acima de {{money}}",
  "FoodC_C_You_have_EjAA": "Você recebeu",
  "FoodD_Activity_No_activity_fhqd": "Sem histórico de recompensas",
  "FoodC_c_Login_for_puGR": "Entre para ver mais informações",
  "FoodC_C_Coupon_Purchase_zdkl": "Histórico de compras",
  "FoodC_C_Payment_money_XOtx": "Comprar por {{money}}",
  "FoodC_C_Buy_again_ScTm": "Pode ser comprado novamente em {{countdown}}",
  "FoodC_button_Download_DUuD": "Baixar {{product_name}}",
  "FoodC_CGC_Copy_MtAU": "Copiar",
  "FoodC_Notifications_Completed_pEhY": "Concluído",
  "FoodD_Activity_Single_Step_xtWh": "Recompensa por volume de pedido",
  "FoodC_Notifications_Invite_successful_RBjt": "Convites realizados",
  "FoodC_C_Time_of_LACt": "Hora da compra",
  "FoodC_page_ZTLT_FASC": "Sem cupons? Obtenha mais vantagens de entrega gratuita aqui",
  "FoodC_C_Go_tdhw": "Solicitar agora",
  "soda-m-i18n---奖励规则": "Regras da recompensa",
  "FoodC_CGC_Cruel_departure_Smts": "Agora não",
  "FoodC_C_Purchase_amount_RXkR": "Valor da compra",
  "FoodC_C_Buy_again_iOeQ": "Compre novamente em {{day}} dias",
  "FoodC_C_Unlimited_store_Ujro": "Aproveite esta oferta e economize {{money}} com as taxas de entrega em seus próximos pedidos!",
  "FoodC_C_Mission_expired_vxOU": "Não há tarefas disponíveis no momento",
  "FoodD_Activity_In_progress_NrnP": "Ativo",
  "FoodC_C_My_coupon_fmaV": "Meus cupons",
  "FoodC_1_Coupon_received_EEkp": "Nenhum cupom",
  "FoodC_C_No_coupon_JTJL": "Não há pacotes de cupons disponíveis para compra",
  "FoodC_C_Order_assignments_xxGZ": "Tarefa do pedido",
  "FoodC_CGC_Go_and_fnbj": "Resgatar agora",
  "FoodC_1_Share_it_scKc": "Compartilhe para ganhar descontos!",
  "FoodD_Activity_Single_reward_ZrZS": "Recompensa por volume de pedido",
  "FoodC_CGC_has_successfully_CrTW": "{{x}} amigos convidados",
  "FoodC_Task_Successfully_received_jXDz": "Você recebeu {{coupon}} cupom(ns)",
  "FoodC_1_Coupon_Account_kcLB": "Perfil atual: {{phone_number}}",
  "FoodC_all_No_reward_ocGJ": "Não há recompensas",
  "DiDi_Food_(C)_Suspended_Delivery_status_Delivery_Pause_PIPS": "Indispon. no momento.",
  "FoodC_1_Everyone_is_Tdgu": "Todos estão recebendo recompensas",
  "FoodC_C_Go_VWzj": "Pedir agora",
  "FoodC_C_Collection_rqwP": "Resgatar",
  "FoodC_toast__SVYJ": "Oferta resgatada",
  "FoodC_C_The_end_yvSK": "Termina em",
  "FoodC_V1_Click_receive_fBbu": "Resgatar agora",
  "FoodC_page_eUPd_sDHg": "Regras",
  "FoodC_C_Universal_Coupon_qLGm": "Cupom universal",
  "FoodC_C_Used_tgaq": "Usado",
  "FoodC_C_Only_money_ZEKz": "Pague apenas {{money}}",
  "FoodD_Activity_Number_of_OgKt": "Recompensa por pessoas indicadas",
  "FoodC_C_Expired_AEpg": "Expirado",
  "FoodB_Part2_Detailed_description_mxuF": "Detalhes",
  "FoodC_CGC__name_Mjiz": "{{name}} ganhou um desconto de {{x}}",
  "FoodC_CGC_Invite_immediately_dQRx": "Enviar convite",
  "FoodB_supplement_Network_connection_RVcl": "Falha na conexão. Verifique suas configurações de rede",
  "soda-d-i18n---无任何记录": "Vazio",
  "FoodC_page_Rewards_will_NhMd": "Sua recompensa será adicionada ao seu perfil até 48 horas após uma indicação bem-sucedida. Para ver as recompensas, acesse {{\t\nproductname_didi_food}} > Carteira > Cupons",
  "FoodC_C_Bonded_package_lroO": "Loja de pacote de cupons",
  "推荐有奖": "Recompensas por indicação",
  "同意": "Concordo",
  "当前无活动": "Nenhuma atividade atual",
  "周日": "Domingo",
  "该城市暂无活动": "No momento, não há ações nesta cidade",
  "在购买后的一个月内都可以使用": "Disponível para uso dentro de um mês após a compra",
  "取消": "Cancelar",
  "网络异常，请稍后再试": "Erro de conexão, tente novamente mais tarde",
  "手机号码错误": "Número de telefone inválido",
  "优惠券使用门槛及有效期详见券面提示，未在券有效期限内使用完毕的，不可继续使用": "O uso do cupom e os detalhes do período de validade são exibidos no próprio cupom. Não é possível usar cupons após o período de validade ",
  "选择国家或地区": "Selecione o país ou a região",
  "邮件退订": "Cancelar o recebimento de e-mails",
  "输入兑换码": "Insira o código promocional",
  "详细规则": "Ver as regras da ação",
  "Share": "Compartilhar",
  "暂时不需要%s的服务": "Serviço temporário não é necessário",
  "支付成功": "Pagamento bem sucedido",
  "我们正在努力": "Trabalhando nisso",
  "是，半年之内不要给我发邮件": "Tenho certeza, não me envie mais e-mails nos próximos 6 meses",
  "将优惠分享到": "Compartilhar promoção com",
  "你已经打开过这个礼盒了": "Você já abriu esse presente",
  "邮件退订结果": "Resultado do cancelamento dos e-mails",
  "暂无活动": "Nenhuma ação disponível",
  "推荐记录": "Histórico de indicação",
  "知道了": "Entendi",
  "恭喜你获得%s张优惠券，快去使用吧": "Parabéns! Você tem cupons de %s. Use-os para fazer o pedido",
  "X推D": "Indicação de X-D",
  "X推C": "Cliente indicado por X",
  "不支持现金支付": "Não suporta pagamentos em dinheiro",
  "运营活动": "Promoção de operações",
  "该浏览器不支持自动复制": "Este navegador não aceita cópia automática",
  "对此类邮件不感兴趣": "Não tenho interesse nesse tipo de e-mail",
  "定位服务未开启，需要先开启定位服务才能参与活动！": "Você só pode participar da ação com o serviço de localização ativado",
  "购买": "Comprar",
  "退订理由：": "Motivo do cancelamento da inscrição:",
  "登录": "Entrar",
  "券账号：%s": "Perfil atual: %s",
  "查看其它活动": "Ver outros eventos",
  "新用户额外惊喜奖励": "Novos usuários têm mais desconto surpresa",
  "%s天后过期": "Expira em %s dias",
  "输入手机号码获取优惠券": "Digite seu número de telefone e receba cupons!",
  "现金": "Dinheiro",
  "单笔订单金额满足优惠券使用门槛金额时方可使用，单笔订单只能使用一张优惠券，使用优惠后，如订单金额超出部分需用户自行支付": "É possível usar um cupom quando um pedido estiver dentro de seu valor limite. Só é possível usar um cupom por pedido. Qualquer quantia que ultrapasse o valor do cupom deverá ser paga pelo cliente",
  "可抵扣%s": "Aplicável para %s",
  "仅限%s使用": "Somente para %s",
  "仅限%s": "%s",
  "领取记录": "Histórico de recompensa",
  "立即兑换": "Resgatar agora",
  "规则详情": "Regras detalhadas",
  "您邀请的好友需通过%s下单，通过其他渠道下单不发放邀请奖励": "O amigo que você convidar precisa fazer um pedido pelo aplicativo %s. Pedidos feitos por outros canais não atenderão ao requisito para o recebimento de uma recompensa por convite",
  "折": "OFF",
  "是，再也不要给我发邮件了": "Tenho certeza, não me envie mais e-mails",
  "如果您退订邮件，可能会错过重要消息，您确定不再接收%s给您发送的邮件吗？": "Quando você cancela a inscrição, pode perder mensagens importantes. Quer mesmo parar de receber estes e-mails?",
  "输入手机号": "Insira o número de telefone",
  "周六": "Sábado",
  "待入账": "Pendente",
  "在这里购买！": "Compre aqui!",
  "阶梯奖励": "Recompensas em escala",
  "很遗憾，未能领取成功": "Desculpe! Não foi possível resgatar esta promoção",
  "活动时间": "Hora da promoção",
  "点击领取": "Resgatar agora",
  "落地页": "Landing page",
  "C运营弹窗": "Janela pop-up de Operações C",
  "隐藏": "Ocultar",
  "%s可用": "Disponível em %s",
  "POS": "Maquininha",
  "添加信用卡/借记卡": "Adicionar cartão de crédito / débito",
  "满%s可用": "acima de %s",
  "T&C": "Termos e Condições",
  "活动说明": "Descrições da ação",
  "Email": "E-mail",
  "售罄": "Esgotado",
  "当前定位城市：": "Cidade atual definida:",
  "领取失败": "Não é possível solicitar",
  "网络似乎存在问题，请稍后重试": "Parece que há um problema de rede, tente novamente mais tarde",
  "券包": "Pacote de cupons",
  "附近没有商家": "Não há estabelecimento próximos",
  "分享给朋友，一起享优惠吧！": "Compartilhe para ganhar descontos!",
  "请登录": "Por favor, inicie sua sessão",
  "选择城市": "Selecione a cidade",
  "您已经成功领取优惠券": "Cupom recebido com sucesso",
  "兑换成功": "Resgatado",
  "《法律声明": "Termos de Uso",
  "当日有效": "Válido no dia",
  "更多详情": "Mais informações",
  "有效期至": "válido até",
  "支付方式": "Forma de pagamento",
  "可获得%s优惠券": "Obter cupons de %s",
  "邀请": "Convidar",
  "等待支付结果": "Aguardando por resultados de pagamento...",
  "轻松来获得一个非常棒的优惠券包": "Fácil de obter um ótimo pacote de cupons",
  "应付金额": "Valor do pagamento",
  "请开启定位": "Ative o serviço de localização",
  "支付超时！": "O tempo limite do pagamento expirou!",
  "奖励会在邀请成功后的48小时内发放入您的账号，您可在%s-钱包-券中查看": "As recompensas serão enviadas à sua conta em até 48 horas após um convite bem-sucedido. Para vê-las, abra seu aplicativo %s e acesse “Carteira > Cupons”",
  "购买含4张$10mxn无门槛券的优惠券包仅需支付$20mxn": "Compre um pacote de cupons com 4 cupons ilimitados de US $ 10 milhões por apenas US $ 20 milhões",
  "去升级": "Para atualizar",
  "复制成功": "Copiado",
  "免配送费": "Grátis",
  "重新加载": "Recarregar",
  "支付": "Pagar",
  "是，一年之内不要给我发邮件": "Tenho certeza, não me envie mais e-mails nos próximos 12 meses",
  "%s日内有效": "Válido por %s dias",
  "仅限新用户使用": "Somente para novo cliente",
  "一经发现任何违规、套现行为，将追回相关金额或者封停账号，且依法追究其法律责任": "Qualquer violação das regras ou tentativa de descontar um cupom pode resultar na dedução da quantia em questão ou na suspensão da conta. Os responsáveis pela violação também podem ser implicados juridicamente",
  "有效时间:": "válido até:",
  "当前无有效活动": "Sem promoções ativas",
  "退订": "Cancelar assinatura",
  "网络不佳，请检查您的网络": "Problemas na conexão, verifique sua conexão de rede",
  "重试": "Tente novamente",
  "周四": "Quinta-feira",
  "查看更多": "Ver",
  "本优惠活动仅限%s[在线支付]的订单享受，不可提现转赠": "Esta promoção só está disponível para pedidos da %s [pagamento online]. Não é possível transferi-la ou trocá-la por dinheiro",
  "通过SNS邀请": "Compartilhar nas redes sociais",
  "分钟": "Min",
  "请选择支付方式": "Selecione uma forma de pagamento",
  "恭喜你！兑换成功！": "Parabéns! Cupom resgatado!",
  "优惠券中心": "Cupons do dia",
  "%s 人": "%s Pessoa",
  "我的优惠券": "Meus cupons",
  "我的邀请码": "Meu código de indicação exclusivo",
  "获取优惠券": "Obter cupons",
  "网络繁忙，请稍后再试": "Rede ocupada, tente novamente mais tarde",
  "我的奖励": "Minhas recompensas",
  "C端落地页": "Landing page da 99Food",
  "兑换失败": "Não foi possível resgatar",
  "很遗憾您无法接收我们的最新消息了，感谢您使用%s！": "Que pena que você não vai mais receber nossas mensagens. Agradecemos por usar a %s!",
  "待入账奖励": "Recompensas pendentes",
  "加载失败": "Não é possível carregar",
  "必须同意用户条款，才能继续下一步": "Você precisa concordar com os Termos de Uso para prosseguir",
  "奖励进度": "Progresso da recompensa",
  "领取成功": "Resgatado!",
  "附近商家": "Estabelecimentos próximos",
  "暂无优惠券": "Nenhum cupom",
  "卡类型": "Tipo de cartão",
  "格式不正确": "Formato incorreto",
  "周五": "Sexta-feira",
  "订单": "Pedido",
  "不可用原因": "Motivo inválido",
  "请您升级到最新的APP版本，体验外卖功能": "Atualize para a versão mais recente do APP para experimentar a função de take-away",
  "周二": "Terça-feira",
  "邀请一个好友即可获得": "Convide um amigo para ganhar cupons",
  "券包购买已超限制": "A compra do cupom excedeu o limite",
  "返回首页": "Ir para o início",
  "与": "and",
  "最高抵扣%s": "Desconto máximo de %s",
  "您邀请的好友必须是%s新用户，每人仅限享受1次新用户首单优惠，同一手机号、同一设备、同一支付账号视为同一用户": "O amigo que você convidar precisa ser um cliente novo da %s. Cada pessoa só pode receber um “Desconto de primeiro pedido para cliente novo”. O mesmo número de telefone, o mesmo dispositivo e a mesma conta para pagamento são considerados como o mesmo cliente",
  "邮件频率过高": "Os e-mails são muito frequentes",
  "没有更多了": "Fim dos resultados",
  "收餐人手机号与领取券时输入的手机号和账号注册手机号必须为同一号码": "O número de telefone do destinatário do pedido, o número inserido após a reivindicação do cupom e o número usado para registrar a conta devem ser iguais",
  "当前手机系统不支持自动复制": "O sistema de celular atual não aceita cópia automática",
  "仅售": "Apenas",
  "去预订食物": "Ir para reservar comida",
  "More": "Mais",
  "有效期至%s": "Válido até: %s",
  "兑换优惠券": "Resgatar cupom",
  "收起": "Ver menos",
  "大家都在领取奖励": "Todos estão recebendo recompensas",
  "配送费": "Taxa de entrega",
  "去看看": "Ir para o início",
  "兑换失败，请再试一次": "Erro. Tente novamente",
  "确认": "Confirmar",
  "已过期": "Expirado",
  "本优惠活动仅限[%s]开通%s区域": "Esta promoção só está disponível em áreas em [%s] onde a %s estiver disponível",
  "此活动尚未在您的城市开通": "Ação ainda não disponível em sua cidade",
  "确定": "OK",
  "去使用": "Usar agora",
  "%s": "%s",
  "支付中": "Pagar",
  "当天过期": "válido por hoje",
  "去看看其他的": "confira outras promoções",
  "在您的订单中使用这些优惠！": "Use estas ofertas em seu pedido!",
  "具体活动规则见": "Para mais detalhes, consulte o Regulamento",
  "已获得奖励": "Recompensas ganhas",
  "条款和条件": "termos e Condições",
  "SMS": "SMS",
  "配送": "Entrega",
  "为你的第一单领取优惠": "Obter desconto para o seu primeiro pedido",
  "恭喜你获得一张%s优惠券，快去使用吧": "Parabéns! Você tem um cupom de %s. Use-o para fazer o pedido",
  "复制": "Copiar",
  "通用支付": "Todos os tipos de pagamento",
  "99Pay-余额": "99Pay - Saldo",
  "我知道了": "Eu sei",
  "《法律声明与隐私政策》": "Termos de Uso e Política de Privacidade",
  "OFF": "OFF",
  "券被领完": "Nenhum cupom",
  "卡": "Cartão",
  "兑换中": "Em andamento",
  "优惠券已放入": "Cupom enviado",
  "周三": "Quarta-feira",
  "关于本活动的任何问题，请联系%s客服": "Se você tiver dúvidas sobre esta promoção, entre em contato com o serviço de Atendimento ao Cliente da %s no número ",
  "邀请好友成为%s新用户，好友领取您送出的红包，并在活动时间截止前完成订单且无退款，无论是否使用领取的红包下单，您均可获得%s[优惠券]奖励": "Convide um amigo para que ele se torne um novo cliente %s e possa resgatar um cupom enviado por você. Se ele fizer um pedido antes do término da promoção e não pedir o reembolso (ele pode usar seu cupom ao fazer o pedido ou não), você pode receber %s [Cupons] Recompensas",
  "已参与过同类型活动</br>快去下单吧": "Você já participou de uma promoção parecida</br>Pode fazer o pedido!",
  "推荐骑手": "Indicar parceiros",
  "活动已结束": "Esta ação foi encerrada",
  "今日领取次数达上限": "Limite diário atingido",
  "看看其他的活动": "Veja outras atividades",
  "周一": "Segunda-feira",
  "隐私政策》": "Privacy Policy",
  "FoodC_birthday_nxQg_feGV": "Remaining uses: {{n}}",
  "FoodC_unsubscription_moTV_YOeR": "We’ll use this information to improve our membership services.",
  "FoodD_optimization_lXdy_Scjt": "Detailed rules",
  "FoodC_update_mXRF_lKCK": "New",
  "FoodC_update_YSxw_ZNzh": "First month",
  "FoodC_shoprank_OYFZ_jaot": "Detailed Rules",
  "FoodC_subscription_uipE_quiy": "Back",
  "FoodC_V2_kGDN_zGcg": "By subscribing, you authorize {{didifoodbrand}} to automatically charge you {{money}} on a monthly basis starting on {{date}}. You can unsubscribe anytime with no additional fees or penalties. {{rules}}",
  "FoodC_update_UDFM_jhpN": "Free delivery on all orders",
  "FoodC_V2_RTeI_mEFs": "Starting from {{date}}, you’ll be automatically charged {{money}} per month. You can cancel your subscription anytime.",
  "FoodC_CGC_SaMA_ymHl": "This event is only for {{app_name}} orders paid online, and rewards cannot be withdrawn or transferred",
  "FoodC_birthday_UhuO_lUDL": "Unable to claim coupons. Please try again later",
  "FoodC_CGC_XBGw_UVvR": "Friends you invite must be eligible to the event rules of {{app_name}}. Each user is entitled to enjoy the reward only {{num}}times. Users with the same mobile number, device or payment account will be considered to be the same user",
  "FoodC_subscription_skWN_HfEE": "Auto Renewal",
  "FoodC_pause_iXVD_owuh": "Your {{brand}} subscription will automatically resume after the pause has ended, and you will be charged {{money}} on {{date}}. {{tc}}",
  "FoodC_unsubscription_tNxS_UdhI": "Please select a reason",
  "FoodC_pause_Wukv_RhXR": "Pause Subscription",
  "FoodC_subscription_StNY_sWmE": "By submitting payment, you agree to the {{rules}}.",
  "FoodB_V2_dLkl_pbiF": "To perform this action, please update to the latest version of the app",
  "FoodC_url_reaV_LXaO": "Use Now",
  "FoodC_update_wBxB_qtoB": "Subscribe to {{brand2}} and enjoy your first month free. You will authorize {{didifoodbrand}} to automatically charge you {{money1}}/mo for the next two months and {{money2}}/mo each month thereafter. You can unsubscribe anytime with no additional fees or penalties. {{rules}}",
  "FoodC_update_VNPd_BxNP": "Free Upgrade to {{brand1}}",
  "FoodC_CGC_qcLp_cjum": "This event is only available in selected areas where the {{app_name}} friends invitation event is offered, including: {{city_list}}",
  "FoodC_pause_izJa_kTkD": "Want to pause your membership subscription?",
  "FoodC_CGC_KVtY_VNSE": "Invite friends to order on {{app_name}}. As long as your friend claims a coupon shared by you and completes an order without requesting a refund before the event ends, you will receive a coupon reward of {{amount}} whether the claimed coupon is used or not",
  "FoodC_subscription_uwdO_LCcw": "Booster Pack",
  "FoodD_optimization_Wiuc_vkvD": "Invite a Friend",
  "FoodC_register_cCpr_KUUG": "Payment Unsuccessful",
  "FoodC_words_vHza_gIgE": "Join {{brand}} for {{money}}/mo",
  "FoodC_words_TYXi_ntMY": "Then {{money}}/mo",
  "FoodC_CGC_WOQY_zerr": "The recipient’s mobile number for receiving orders must be the same as the mobile number used for claiming coupons and account registration.",
  "FoodC_unsubscription_EyKD_bpPY": "Continue using {{brand}}",
  "FoodC_update_KTVU_dCMP": "Unlimited",
  "FoodC_V2_RUXY_aLKJ": "Auto-Pay Method",
  "FoodC_update_QaEe_FhNn": "{{money}}/mo",
  "FoodC_subscription_CYiq_FQpm": "Price: {{money}}",
  "FoodC_register_qELy_skhp": "Please try another payment method",
  "FoodC_rule_14_Up_dovx": "Limit 2 coupons per customer per day",
  "FoodC_update_PJJg_EEDD": "Next 2 months",
  "FoodC_update_KqNE_JARP": "Use {{brand1}} free for 30 days",
  "FoodC_subscription_gNZb_xfcd": "Unsubscribe from {{didipackbrand}}",
  "FoodC_birthday_uoAk_Rmzj": "Tap here to view your coupons",
  "FoodC_unsubscription_xxOV_zoQL": "Why do you want to cancel your subscription?",
  "FoodD_optimization_WVNI_kCwK": "{{day}} day(s) {{hour}} hour(s) left to invite",
  "FoodC_subscription_JZaZ_ToBk": "Only {{money1}} for the first month to join {{didipackbrand}} and subscribe. You will authorize {{didifoodbrand}} to automatically charge you {{money2}} in the following month. You can unsubscribe anytime with no additional fees or penalties.",
  "FoodC_V2_ryoa_JKea": "Add a payment method",
  "FoodC_pause_LiWX_icgn": "Your subscription has been resumed!",
  "FoodC_pause_pfAh_qUrD": "Pause Membership",
  "FoodC_subscription_arhI_yUex": "Keep My Benefits",
  "FoodC_pause_xeiI_PONf": "No Thanks",
  "FoodC_birthday_mwOE_zhiz": "Got It",
  "FoodC_pause_AtFL_nncW": "Subscription Paused",
  "FoodC_didipack_XQlZ_sNjR": "Only {{money1}} for the first month to join {{didipackbrand}} and enable your subscription. You will authorize {{didifoodbrand}} to automatically charge you {{money2}} in the following month. You can unsubscribe anytime with no additional fees or penalties. If you request to unsubscribe after payment for the current month has already been made, your subscription will be cancelled the following month.",
  "FoodC_birthday_bthp_qePu": "Confirm",
  "FoodD_optimization_jCaj_SrWJ": "There are no active reward events in the current city",
  "FoodC_pause_cYix_reoR": "Your {{brand}} subscription will be paused on {{date}}. While your subscription is paused, you won’t be charged and won’t receive any member perks.",
  "FoodC_update_ZFGk_VVRa": "View Details",
  "FoodC_pause_NJOj_iqnX": "Membership subscription is paused.",
  "FoodC_update_BIRW_NftU": "Use {{brand1}} free for 30 days (Upgrade from {{brand2}})",
  "FoodC_V2_RlIh_OuIP": "Payment method",
  "FoodC_birthday_teJB_YsTQ": "Birthday: {{date}}",
  "FoodC_birthday_lxme_nZrh": "Set Your Birthday",
  "FoodC_shoprank_Qwab_yWBb": "Got It",
  "FoodC_register_mezq_UlpA": "Got It",
  "FoodC_words_ikBq_XDxq": "Join {{brand}} for {{money}}",
  "FoodC_update_pgdT_nUsj": "Average savings of {{money}}",
  "FoodC_V2_SvHk_RdVM": "Congrats! You successfully joined {{didipackbrand}}",
  "FoodD_optimization_MkEn_uAUM": "Try switching cities",
  "FoodC_subscription_LiqM_lRQw": "DiDi Club",
  "FoodC_CGC_kRMT_EBEL": "Any friends you invite must order via {{app_name}}. Rewards won’t be issued for orders placed through other channels",
  "FoodC_CGC_QXwb_BKmD": "Event Period: {{startdate}} to {{enddate}}",
  "FoodC_member_qXYs_kKZA": "Got It",
  "FoodC_subscription_pODC_SAYm": "Expired",
  "FoodC_V2_syWI_awMo": "Credit / debit card",
  "FoodC_CGC_gLdR_yRZb": "If any violations or cash-out activities are discovered, the relevant amount will be recovered or the account will be suspended, and legal responsibilities will be pursued in accordance with the law.",
  "FoodC_subscription_YJLt_vjMk": "Join {{didipackbrand}}",
  "FoodC_birthday_JshR_FWPW": "Date cannot be modified once set",
  "FoodB_20230410_YNjt_xAWA": "DiDi Top",
  "FoodC_birthday_ZEpi_KbUs": "Coupons Claimed Successfully",
  "FoodC_pause_VaEr_OWMS": "Subscription Paused",
  "FoodC_didipack_uwFV_TgLK": "Become a member and save {{money}}/month",
  "FoodC_words_MBwE_Wmhg": "{{money}} for the first month",
  "FoodC_birthday_hJRO_UaMS": "Claim Birthday Coupons",
  "FoodC_invite_Step_2_AhBV": "Step 2: The new user signs up and places an order",
  "FoodC_subscription_RApp_AMMC": "By purchasing {{didipackbrand}}, you agree to the {{rules}}. You’ll be charged {{money}} on the billing day of every month. You can unsubscribe for free anytime.",
  "FoodC_CGC_xGpr_jdrf": "If you have any questions about this event, please contact {{app_name}} Customer Service",
  "FoodC_unsubscription_oSsY_xaBg": "Tell us more (Optional)",
  "FoodC_invite_Step_3_VjuY": "Step 3: Earn rewards",
  "FoodC_subscription_CtEg_IEoy": "Unsubscribe from {{didipackbrand}}",
  "FoodC_subscription_yHJx_Ospz": "Validity period: {{date1}} – {{date2}}",
  "FoodC_words_bshC_QpQz": "Join {{brand}} for {{money}} for 1st Month",
  "FoodC_unsubscription_PWOD_DyeO": "You have successfully subscribed!",
  "FoodC_pause_aidJ_DeYv": "Your subscription was cancelled.",
  "FoodC_update_lgoe_CKtm": "Free",
  "FoodC_subscription_eceZ_mHfd": "You’ll be automatically charged {{money}} on {{date}}. You can unsubscribe anytime with no additional fees or penalties.",
  "FoodD_optimization_KHwe_UDpu": "View all",
  "FoodC_didipack_kIYJ_MqHw": "You will authorize {{didifoodbrand}} to automatically charge you {{money2}} in the following month. You can unsubscribe anytime with no additional fees or penalties. If you request to unsubscribe after payment for the current month has already been made, your subscription will be cancelled the following month.",
  "FoodC_invite_Step_1_IIde": "Step 1: Invite a new user",
  "FoodD_optimization_Qqcn_TvQv": "No invites. See History for more",
  "FoodD_optimization_pADD_hRLc": "Other Invite Options",
  "FoodC_member_wEQc_Appv": "You’ve already selected to purchase {{brand}} as part of your current order. Simply complete your order to become a member!",
  "FoodC_pause_dEKK_qnxf": "Your membership subscription has been paused, and member perks will be unavailable for {{num}} month(s) starting from {{date1}}. Your subscription to {{brand}} will resume on {{date2}} and you will be automatically charged {{money}}. {{tc}}",
  "FoodC_subscription_KlqY_fMjr": "Valid",
  "FoodC_member_fKsy_UJNP": "View Order",
  "FoodC_birthday_MdbO_KlNx": "Tell us your birthday",
  "FoodD_optimization_rIEQ_UWUa": "Share",
  "FoodC_member_rIZz_EbdA": "You’re already purchasing a membership",
  "FoodC_CGC_JFyK_TjqE": "Coupon usage limits and validity period are displayed on the coupon. Coupons may only be used during the validity period",
  "FoodC_subscription_XfQl_RWPP": "Subscription",
  "FoodC_unsubscription_qlCR_GqbU": "Cancel Subscription",
  "FoodC_birthday_vcey_tequ": "View Coupons",
  "FoodC_update_FOFJ_UkRW": "5% off all rides",
  "FoodC_subscription_HZKf_jObw": "You currently don’t have an active subscription",
  "FoodD_optimization_hPwx_hGCS": "History",
  "FoodC_subscription_Jnyy_Zokk": "Your {{didipackbrand}} subscription is being renewed. Please check back later",
  "FoodC_V2_NigJ_XaWd": "Subscribe to {{didipackbrand}}",
  "FoodC_update_iSQF_topi": "No service fees on all orders",
  "FoodC_update_rJfl_rGTF": "No Service Fees",
  "FoodC_STORE_sDEb_djsn": "Ranking",
  "FoodC_subscription_Wlpe_bBkf": "History",
  "FoodC_update_fIEP_oDcY": "Upgrade",
  "FoodC_unsubscription_tnHb_xMuO": "Next",
  "FoodC_update_ZTvS_WSTC": "Free Delivery",
  "FoodC_V2_qWhb_IjVg": "Subscribe",
  "FoodC_update_fyhl_EPiz": "5% Off Rides",
  "FoodC_didipack_ruhC_zsFU": "Join {{didipack}} for {{money}}",
  "FoodC_CGC_PFoL_QAIo": "A coupon can only be used when a single order amount exceeds the coupon threshold amount. Only one coupon may be used per order. Any amount in excess of the discount shall be paid by the user",
  "FoodC_pause_qsCr_nszu": "Resume Subscription",
  "FoodC_CGC_wkuZ_BrqU": "Rewards are issued based on the city in which the [person referred] is located",
  "FoodC_register_WoRy_tjec": "Please select a payment method",
  "Gattaran_reffral_Kjwv_iVSZ": "Invite Friends",
  "FoodC_cash_wnPC_qiCD": "Use Now",
  "FoodC_update_VTVc_ZtNA": "Thereafter",
  "FoodC_subscription_uoAf_TiHz": "Thank you for using {{didipackbrand}}. If you unsubscribe, your {{didipackbrand}} membership will expire on {{date}}. Are you sure you want to unsubscribe?",
  "FoodC_subscription_QaXu_EIIa": "Manage {{didipackbrand}}",
  "FoodC_V2_SSNq_tsZF": "Got It",
  "FoodC_subscription_VPMP_seXT": "Terms and Conditions",
  "FoodC_subscription_jyMF_Ybuq": "You’re currently enjoying a free trial of {{brand}}. If you unsubscribe now, your free trial will expire immediately and you won’t be eligible for Free Delivery on food orders or other member perks.",
  "FoodC_subscription_DzKZ_WhvD": "Date of purchase: {{date}}",
  "FoodC_update_TDwr_XZVd": "My {{brand2}}",
  "FoodC_CGC_UFif_hfJM": "Rewards will be issued to your account within {{num}} hour(s) after a successful invite. You can view your rewards by going to {{app_name}} > Wallet > Coupons",
  "FoodC_center_Ospi_SNsH": "Totals savings so far",
  "FoodC_pause_tfPj_IAoM": "Your subscription to {{brand}} will resume on {{date}} and you will be automatically charged {{money}}. {{tc}}"
}