import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Mustache from 'mustache'
import { getLanguageLong } from '@didi/gettext'
import { i18nInit, i18nGet } from '@didi/i18n-plugin'
import emergency from './emergency'

i18nInit(require('./i18n-info'))

import zhCN from './zh-CN'
import enUS from './en-US'
import ptBR from './pt-BR'
import jaJP from './ja-JP'
import esMX from './es-MX'
import esCO from './es-CO'
import es419 from './es-419'

Object.assign(zhCN, emergency['zh-CN'])
Object.assign(enUS, emergency['en-US'])
Object.assign(ptBR, emergency['pt-BR'])
Object.assign(jaJP, emergency['ja-JP'])
Object.assign(esMX, emergency['es-MX'])
Object.assign(esCO, emergency['es-CO'])
Object.assign(es419, emergency['es-419'])

Vue.use(VueI18n)
// https://www.npmjs.com/package/mustache#custom-delimiters
Mustache.escape = function (text) {
  return text;
}
class CustomFormatter {
  interpolate (message, values) {
    // 觉得Mustache太重，可以实现一个简版
    return Mustache.render(message, values)
  }
}

export default new VueI18n({
  locale: getLanguageLong(), // 设置地区
  formatter: new CustomFormatter(), // 适配I18N翻译平台占位符 {{variable}}
  messages: {
    'en-US': i18nGet(enUS, 'en-us'),
    'zh-CN': i18nGet(zhCN, 'zh-cn'),
    'pt-BR': i18nGet(ptBR, 'pt-br'),
    'es-MX': i18nGet(esMX, 'es-mx'),
    'es-CO': i18nGet(esCO, 'es-co'),
    'ja-JP': i18nGet(jaJP, 'ja-jp'),
    'es-419': i18nGet(es419, 'es-419'),
  },
})
