module.exports = {
  "FoodC_C_Detailed_rules_WfnL": "详细规则",
  "FoodC_birthday_nxQg_feGV": "剩余次数：{{n}}",
  "FoodC_unsubscription_moTV_YOeR": "我们将利用这些信息来改进会员服务。",
  "FoodD_optimization_lXdy_Scjt": "更多规则",
  "FoodC_update_mXRF_lKCK": "新增",
  "FoodC_update_YSxw_ZNzh": "第一个月",
  "FoodC_shoprank_OYFZ_jaot": "详细规则",
  "FoodC_subscription_uipE_quiy": "返回",
  "FoodC_V2_kGDN_zGcg": "开启订阅即表示您授权{{didifoodbrand}}于{{date}}自动扣除您{{money}}，并在之后每个月收取此金额。可随时取消订阅，取消时无需支付其他费用或罚金。{{rules}}",
  "FoodC_update_UDFM_jhpN": "无限次外卖免配送费",
  "FoodC_store_Store_Coupon_szoR": "商家券",
  "FoodC_Task_Congratulations_pDXl": "恭喜！",
  "FoodC_V2_RTeI_mEFs": "从{{date}}起，每个月会自动扣款{{money}}，可随时取消订阅。",
  "FoodC_CGC_Other_channel_oFak": "其他渠道邀请",
  "FoodC_Notifications_Invite_users_Ovxe": "邀请用户",
  "FoodC_C_Off_fkZy": "off",
  "FoodC_CGC_SaMA_ymHl": "本优惠活动仅限{{app_name}}[在线支付]的订单享受，不可提现转赠",
  "FoodC_birthday_UhuO_lUDL": "券领取失败，请稍后重试",
  "FoodC_CGC_XBGw_UVvR": "您邀请的好友必须是符合{{app_name}}活动规则的用户，每人仅限享受{{num}}次奖励，同一手机号、同一设备、同一支付账号视为同一用户",
  "FoodC_subscription_skWN_HfEE": "自动续费",
  "FoodC_pause_iXVD_owuh": "暂停结束后会自动重新加入{{brand}}，您将在{{date}}被扣款{{money}}。{{tc}}",
  "FoodC_unsubscription_tNxS_UdhI": "请选择原因",
  "FoodC_pause_Wukv_RhXR": "暂停订阅",
  "FoodC_subscription_StNY_sWmE": "完成购买则代表您同意{{rules}}。",
  "FoodB_V2_dLkl_pbiF": "客户端版本低，请更新版本再操作",
  "FoodC_V1_Gift_box_UWqz": "你已经打开过这个礼盒了",
  "FoodC_C_Mission_completed_FkCw": "任务已完成",
  "FoodC_url_reaV_LXaO": "去使用",
  "FoodC_update_wBxB_qtoB": "首月免费体验{{brand2}}，并开启订阅。您将授权{{didifoodbrand}}于接下来两个月自动扣款{{money1}}，再后来每个月自动扣款{{money2}},可随时取消订阅，取消时无需支付其他费用或罚金。{{rules}}",
  "FoodD_Activity_Completed_tPlA": "已结束",
  "FoodC_update_VNPd_BxNP": "免费升级为{{brand1}}",
  "FoodC_CGC_qcLp_cjum": "本优惠活动仅限{{app_name}}邀请好友活动开通的区域，开通城市如下：{{city_list}}",
  "FoodC_details_The_following_bPLC": "以下商家可使用",
  "FoodC_c_Please_try_cHSE": "请稍后再试",
  "FoodC_C_View_Coupon_OIhv": "查看优惠券",
  "FoodC_1_Maximum_number_cJPy": "今日领取次数达上限",
  "FoodC_C_Received_DOQd": "已领取",
  "FoodC_pause_izJa_kTkD": "希望暂停会员订阅？",
  "FoodC_homepage_Unable_to_ybHC": "定位失败",
  "FoodC_CGC_KVtY_VNSE": "邀请好友在{{app_name}}下单，好友领取您送出的红包，并在活动时间截止前完成订单且无退款，无论是否使用领取的红包下单，您均可获得价值{{amount}}的优惠券奖励",
  "FoodC_subscription_uwdO_LCcw": "加量包",
  "FoodD_optimization_Wiuc_vkvD": "邀请你的朋友",
  "soda-d-i18n---奖励详情": "奖励详情",
  "FoodC_2_Enter_mobile_jXbU": "输入手机号",
  "FoodC_register_cCpr_KUUG": "支付失败",
  "FoodC_words_vHza_gIgE": "加入{{brand}}{{money}}/月",
  "FoodC_C_No_coupon_DlRu": "无可使用券包",
  "FoodC_demand_Courier_purchasing_vPGD": "骑手代购",
  "FoodC_words_TYXi_ntMY": "之后{{money}}/月",
  "FoodC_CGC_WOQY_zerr": "收餐人手机号与领取券时输入的手机号和账号注册手机号必须为同一号码",
  "FoodC_unsubscription_EyKD_bpPY": "继续使用{{brand}}",
  "FoodC_update_KTVU_dCMP": "无限次",
  "soda-d-i18n---没有更多了": "没有更多了",
  "FoodC_V2_RUXY_aLKJ": "自动扣款支付方式",
  "FoodC_Notifications_Congratulations_you_qoKt": "恭喜你已经获得奖励",
  "FoodC_page_Your_invited_sPpR": "您邀请的好友必须是{{\t\nproductname_didi_food}}新用户，每人仅限享受1次新用户首单优惠，同一手机号、同一设备、同一支付账号视为同一用户",
  "FoodC_update_QaEe_FhNn": "{{money}}/月",
  "FoodC_C_Mission_Centre_HafM": "任务中心",
  "FoodC_subscription_CYiq_FQpm": "价格:{{money}}",
  "FoodC_page_Rewards_are_dWeY": "奖励按照【被推荐人】定位所在城市发放",
  "FoodC_C_Purchased_YheA": "已购买",
  "FoodC_register_qELy_skhp": "尝试更换其他支付方式再次支付",
  "FoodC_rule_14_Up_dovx": "每个用户每天最多用券2张",
  "FoodC_C_Available_DlSP": "可使用",
  "sailing.d-api.common.活动时间": "活动时间",
  "FoodC_update_PJJg_EEDD": "接下来两个月",
  "FoodOpC_city_Rewards_are_oURm": "奖励按照【被推荐人】定位所在城市发放",
  "FoodC_C_You_will_FoTj": "您将获得",
  "FoodC_page_The_threshold_yZFq": "优惠券使用门槛及有效期详见券面提示，未在券有效期限内使用完毕的，不可继续使用",
  "FoodC_c_Sign_in_gRWz": "登录",
  "FoodC_Translation__brand_LAYp": "{{brand_name}} 优惠券",
  "FoodC_update_KqNE_JARP": "免费使用{{brand1}}30天",
  "FoodC_subscription_gNZb_xfcd": "离开{{didipackbrand}}",
  "FoodC_page_XCBA_oZij": "订单",
  "sailing.c-api-intl.超出配送范围": "超出配送范围",
  "FoodC_birthday_uoAk_Rmzj": "点击这里查看优惠券",
  "FoodC_unsubscription_xxOV_zoQL": "您为什么希望取消订阅？",
  "FoodD_2_Single_amount_LbPB": "单量",
  "FoodD_optimization_WVNI_kCwK": "还剩{{day}}天{{hour}}小时邀请",
  "FoodC_C_No_suitable_UnyN": "没有合适的活动",
  "FoodC_subscription_JZaZ_ToBk": "首月仅需支付{{money1}}即可加入{{didipackbrand}}，并开启订阅。您将授权{{didifoodbrand}}于次月自动扣款{{money2}}。可随时取消订阅，取消时无需支付其他费用或罚金。",
  "FoodC_V2_ryoa_JKea": "添加付款方式",
  "FoodC_pause_LiWX_icgn": "您已恢复订阅！",
  "FoodC_pause_pfAh_qUrD": "暂停会员",
  "FoodC_C_Purchase_Records_CIUH": "购买记录",
  "FoodC_subscription_arhI_yUex": "继续享受优惠",
  "FoodC_pause_xeiI_PONf": "不需要",
  "FoodC_birthday_mwOE_zhiz": "知道了",
  "FoodC_pause_AtFL_nncW": "订阅暂停",
  "FoodC_didipack_XQlZ_sNjR": "首月仅需支付{{money1}}即可加入{{didipackbrand}}，并开启订阅。您将授权{{didifoodbrand}}于次月自动扣款{{money2}}。可随时取消订阅，取消时无需支付其他费用或罚金。如果当月已经扣款，取消订阅将在下个月生效。",
  "FoodC_birthday_bthp_qePu": "提交",
  "FoodD_optimization_jCaj_SrWJ": "当前城市暂时没有活动",
  "FoodC_c_Login_for_puGR": "登录查看更多信息",
  "FoodC_C_Coupon_Purchase_zdkl": "券包购买记录",
  "FoodC_pause_cYix_reoR": "{{brand}}将在{{date}}暂停订阅。暂停期间您不会被收取费用也无法享受任何会员权益。",
  "FoodC_C_Payment_money_XOtx": "支付{{money}}",
  "FoodC_C_Buy_again_ScTm": "{{countdown}}后可再次购买",
  "FoodC_button_Download_DUuD": "下载{{product_name}}",
  "FoodC_update_ZFGk_VVRa": "查看详情",
  "FoodC_CGC_Copy_MtAU": "复制",
  "FoodC_pause_NJOj_iqnX": "会员订阅已暂停。",
  "FoodD_Activity_Single_Step_xtWh": "按单量奖励",
  "FoodC_update_BIRW_NftU": "免费使用{{brand1}}30天（从{{brand2}}升级）",
  "FoodC_Notifications_Invite_successful_RBjt": "邀请成功用户",
  "FoodC_C_Time_of_LACt": "购买时间",
  "FoodC_V2_RlIh_OuIP": "支付方式",
  "FoodC_page_ZTLT_FASC": "如果免配券全部已使用，获得更多免配权益",
  "FoodC_birthday_teJB_YsTQ": "生日：{{date}}",
  "FoodC_birthday_lxme_nZrh": "设置您的生日",
  "FoodC_C_Go_tdhw": "去下单",
  "soda-m-i18n---奖励规则": "Reward Rules",
  "FoodC_shoprank_Qwab_yWBb": "确定",
  "FoodC_CGC_Cruel_departure_Smts": "残忍离开",
  "FoodC_C_Buy_again_iOeQ": "{{day}}天后可再次购买",
  "FoodC_C_Unlimited_store_Ujro": "利用此促销活动，在下一个订单中节省{{money}}的运费！",
  "FoodC_register_mezq_UlpA": "知道了",
  "FoodC_words_ikBq_XDxq": "加入{{brand}}{{money}}",
  "FoodD_Activity_In_progress_NrnP": "进行中",
  "FoodC_1_Coupon_received_EEkp": "券被领完",
  "FoodC_update_pgdT_nUsj": "预计可省{{money}}",
  "FoodC_C_No_coupon_JTJL": "无可购买券包",
  "FoodC_CGC_Go_and_fnbj": "去领取",
  "FoodC_V2_SvHk_RdVM": "恭喜您已加入{{didipackbrand}}",
  "FoodD_optimization_MkEn_uAUM": "尝试切换城市",
  "FoodC_Task_Successfully_received_jXDz": "成功领取{{coupon}}张优惠券。",
  "FoodC_1_Coupon_Account_kcLB": "券账号：{{phone_number}}",
  "DiDi_Food_(C)_Suspended_Delivery_status_Delivery_Pause_PIPS": "暂停配送",
  "FoodC_C_Collection_rqwP": "领取",
  "FoodC_subscription_LiqM_lRQw": "DiDi Club",
  "FoodC_V1_Click_receive_fBbu": "点击领取",
  "FoodC_CGC_kRMT_EBEL": "您邀请的好友需通过{{app_name}}下单，通过其他渠道下单不发放邀请奖励",
  "FoodC_C_Universal_Coupon_qLGm": "全场通用券",
  "FoodC_C_Used_tgaq": "已使用",
  "FoodC_C_Only_money_ZEKz": "仅支付{{money}}",
  "FoodC_CGC_QXwb_BKmD": "活动时间{{startdate}}-{{enddate}}",
  "FoodC_member_qXYs_kKZA": "知道了",
  "FoodC_C_Expired_AEpg": "已过期",
  "FoodB_Part2_Detailed_description_mxuF": "详细说明",
  "FoodC_CGC__name_Mjiz": "{{name}}获得了{{x}}优惠",
  "FoodC_CGC_Invite_immediately_dQRx": "立即邀请",
  "FoodB_supplement_Network_connection_RVcl": "网络连接失败，请检查网络设置",
  "FoodC_page_Rewards_will_NhMd": "奖励会在邀请成功后的48小时内发放入您的账号，您可在{{\t\nproductname_didi_food}}-钱包-券中查看",
  "FoodC_subscription_pODC_SAYm": "已失效",
  "FoodC_Notifications__end_QQPI": "{{end_time}}天后结束",
  "FoodC_page_Upon_discovery_baTq": "一经发现任何违规、套现行为，将追回相关金额或者封停账号，且依法追究其法律责任",
  "FoodC_C_To_be_Qemy": "待使用",
  "FoodC_V2_syWI_awMo": "信用卡/借记卡",
  "FoodC_CGC_gLdR_yRZb": "一经发现任何违规、套现行为，将追回相关金额或者封停账号，且依法追究其法律责任",
  "FoodC_button_Open_fmKT": "打开{{product_name}}",
  "FoodD_Activity_Rewards_will_TMCp": "奖励将在活动结束后计入",
  "FoodC_subscription_YJLt_vjMk": "加入{{didipackbrand}}",
  "FoodC_birthday_JshR_FWPW": "日期设置后不可修改",
  "FoodB_20230410_YNjt_xAWA": "优选好店",
  "FoodC_birthday_ZEpi_KbUs": "优惠券领取成功",
  "FoodC_pause_VaEr_OWMS": "订阅暂停中",
  "FoodC_didipack_uwFV_TgLK": "加入会员可省{{money}}/月",
  "FoodC_coupons_Congratulations_on_YevG": "恭喜您成功领券",
  "FoodC_Notifications_Invite_user_GtIH": "再邀请{{user_num}}位新用户完成首单，即可得{{gift}}",
  "FoodC_words_MBwE_Wmhg": "第一个月{{money}}",
  "FoodC_birthday_hJRO_UaMS": "领取生日券",
  "soda-b-web-i18n---休息中": "休息中",
  "FoodC_invite_Step_2_AhBV": "第二步：新用户登录并下单成功",
  "FoodC_subscription_RApp_AMMC": "购买{{didipackbrand}}则代表您同意{{rules}}，我们会在每个月的扣款日自动收取{{money}}。可随时无责取消订阅。",
  "FoodC_Notifications_Congratulations_gTCr": "恭喜你",
  "sailing.banner-intl.暂停营业": "暂停营业",
  "FoodC_CGC_xGpr_jdrf": "关于本活动的任何问题，请联系{{app_name}}客服",
  "FoodC_1_Receiving_Records_RumV": "领取记录",
  "FoodC_unsubscription_oSsY_xaBg": "告诉我们更多信息（可选）",
  "FoodC_page_Invite_friends_oGuj": "邀请好友成为{{\t\nproductname_didi_food}}新用户，好友领取您送出的红包，并在被介绍后14天内及活动时间截止前，完成订单且无退款，无论是否使用领取的红包下单，您均可获得{{\t\nproductname_didi_food}}[优惠券]奖励",
  "FoodC_invite_Step_3_VjuY": "第三步：获得奖励",
  "FoodC_subscription_CtEg_IEoy": "结束订阅{{didipackbrand}}",
  "FoodC_C_You_will_pQqU": "您将获得{{n}}张券",
  "FoodC_subscription_yHJx_Ospz": "有效期：{{date1}}~{{date2}}",
  "FoodC_C_Coupon_Center_WghP": "券包中心",
  "FoodC_1_Unable_to_yqfz": "领取失败",
  "FoodC_V1_Use_IuaW": "去使用",
  "FoodC_page_This_special_fYuo": "本优惠活动仅限{{\t\nactivity_city}}开通{{\t\nproductname_didi_food}}区域",
  "FoodC_page_For_any_IWNZ": "关于本活动的任何问题，请联系{{contact_email}}客服",
  "FoodD_Activity_Expand_more_VNNc": "展开更多",
  "FoodC_words_bshC_QpQz": "加入{{brand}}第一个月{{money}}",
  "FoodC_unsubscription_PWOD_DyeO": "您已成功开启订阅！",
  "FoodC_C_Terms_and_eAhU": "条款和条件",
  "FoodC_pause_aidJ_DeYv": "您已取消订阅。",
  "FoodC_update_lgoe_CKtm": "免费",
  "FoodC_C_Invitation_CPGR": "邀请",
  "FoodC_c_Please_log_pWNK": "请登录您的账号",
  "FoodC_subscription_eceZ_mHfd": "下一次续订时间为{{date}}，自动扣款金额为{{money}}。可随时取消订阅，取消时无需支付其他费用或罚金。",
  "FoodD_optimization_KHwe_UDpu": "所有记录",
  "FoodC_didipack_kIYJ_MqHw": "您将授权{{didifoodbrand}}于次月自动扣款{{money2}}。可随时取消订阅，取消时无需支付其他费用或罚金。如果当月已经扣款，取消订阅将在下个月生效。",
  "FoodC_invite_Step_1_IIde": "第一步：邀请新用户",
  "FoodC_V1_View_activity_NCOP": "查看其它活动",
  "FoodD_Activity_Number_of_vXPs": "按人数奖励",
  "FoodC_c_Unable_to_cPrS": "无法连接服务",
  "FoodC_C_Back_Home_opQY": "回首页",
  "FoodD_optimization_Qqcn_TvQv": "当前活动无邀请记录，可点击右上角查询历史记录",
  "FoodD_optimization_pADD_hRLc": "其他",
  "FoodC_copyaddJP_Your_mobile_zrXT": "您的手机号码将仅用于绑定本次活动所获得的券，请放心填写",
  "FoodC_member_wEQc_Appv": "您已在当前订单中选择购买了{{brand}}，完成订单即可加入！",
  "FoodC_Notifications_Rewards_received_czXX": "获得的奖励",
  "soda-m-i18n---完成": "Complete",
  "FoodC_all_You_haven_lSmt": "你还没有邀请你的朋友，快去邀请他们吧",
  "FoodC_Notifications_Accept_the_wTgP": "接受邀请",
  "FoodC_pause_dEKK_qnxf": "会员订阅已暂停，会员权益从{{date1}}起{{num}}个月内无法使用。您将在{{date2}}重新加入{{brand}}并被自动扣款{{money}}。{{tc}}",
  "FoodC_subscription_KlqY_fMjr": "生效中",
  "FoodC_member_fKsy_UJNP": "查看订单",
  "FoodC_C_Use_jLVF": "使用",
  "FoodC_birthday_MdbO_KlNx": "告诉我们你的生日",
  "FoodC_C__n_tBvE": "{{n}}张券",
  "FoodD_optimization_rIEQ_UWUa": "分享",
  "FoodC_C_Maximum_deduction_UvNt": "最高抵扣{{money}}",
  "FoodC_CGC_There_is_gZQn": "还有{{x}}金额待领取",
  "FoodC_member_rIZz_EbdA": "会员购买中",
  "FoodC_CGC_JFyK_TjqE": "优惠券使用门槛及有效期详见券面提示，未在券有效期限内使用完毕的，不可继续使用",
  "FoodC_subscription_XfQl_RWPP": "订阅功能",
  "FoodC_c_Try_again_lIZQ": "重试",
  "FoodC_unsubscription_qlCR_GqbU": "取消订阅",
  "FoodC_birthday_vcey_tequ": "查看优惠券",
  "FoodC_update_FOFJ_UkRW": "无限次出行5%off",
  "FoodC_page_hbyT_BKde": "免配送",
  "FoodC_C_Coupon_package_aeUS": "券包",
  "FoodC_subscription_HZKf_jObw": "您未开通订阅",
  "FoodD_optimization_hPwx_hGCS": "历史",
  "FoodC_subscription_Jnyy_Zokk": "{{didipackbrand}}正在续费中，请稍后回来",
  "soda-m-i18n---取消": "Cancel",
  "FoodC_C_More_TIyU": "更多",
  "FoodC_1_Additional_Surprise_bBNh": "新用户额外惊喜奖励",
  "FoodC_C_Up_to_boqc": "--",
  "FoodC_V2_NigJ_XaWd": "订阅{{didipackbrand}}",
  "FoodC_update_iSQF_topi": "无限次外卖免服务费",
  "FoodC_update_rJfl_rGTF": "免服务费",
  "FoodC_STORE_sDEb_djsn": "排行榜",
  "FoodC_subscription_Wlpe_bBkf": "购买记录",
  "sailing.act-api-intl.活动已经结束": "活动已经结束",
  "FoodC_419_zBRR_jLNF": "没有门槛限制",
  "FoodC_C_No_purchase_cMRq": "无购买记录",
  "FoodC_C_Full_money_oqcE": "满{{money}}可用",
  "FoodC_update_fIEP_oDcY": "升级",
  "FoodC_unsubscription_tnHb_xMuO": "下一步",
  "FoodC_C_You_have_EjAA": "您已获得",
  "FoodC_update_ZTvS_WSTC": "免配送",
  "FoodD_Activity_No_activity_fhqd": "暂无活动参与记录",
  "FoodC_V2_qWhb_IjVg": "订阅",
  "FoodC_update_fyhl_EPiz": "出行订单5%off优惠",
  "FoodC_didipack_ruhC_zsFU": "支付{{money}}加入{{didipack}}",
  "FoodC_CGC_PFoL_QAIo": "单笔订单金额满足优惠券使用门槛金额时方可使用，单笔订单只能使用一张优惠券，使用优惠后，如订单金额超出部分需用户自行支付",
  "FoodC_pause_qsCr_nszu": "恢复订阅",
  "FoodC_CGC_wkuZ_BrqU": "奖励按照【被推荐人】定位所在城市发放",
  "FoodC_register_WoRy_tjec": "请选择支付方式",
  "Gattaran_reffral_Kjwv_iVSZ": "邀请好友",
  "FoodC_Notifications_Completed_pEhY": "已完成",
  "FoodC_cash_wnPC_qiCD": "去使用",
  "FoodC_update_VTVc_ZtNA": "之后",
  "FoodC_C_Purchase_amount_RXkR": "购买金额",
  "FoodC_subscription_uoAf_TiHz": "感谢您使用{{didipackbrand}}，结束订阅后，{{didipackbrand}}权益将于{{date}}结束，确定要取消订阅吗？",
  "FoodC_C_Mission_expired_vxOU": "当前没有正在进行的任务",
  "FoodC_subscription_QaXu_EIIa": "管理{{didipackbrand}}",
  "FoodC_C_My_coupon_fmaV": "我的优惠券",
  "FoodC_V2_SSNq_tsZF": "知道了",
  "FoodC_subscription_VPMP_seXT": "规则与条款",
  "FoodC_subscription_jyMF_Ybuq": "你正在享受{{brand}}的免费试用。如果现在取消订阅，你将立即失去免费试用资格，并且无法享受外卖免配送费和其他会员权益。",
  "FoodC_C_Order_assignments_xxGZ": "下单任务",
  "FoodC_subscription_DzKZ_WhvD": "购买时间:{{date}}",
  "FoodC_1_Share_it_scKc": "分享给朋友，一起享优惠吧！",
  "FoodD_Activity_Single_reward_ZrZS": "单数奖励",
  "FoodC_CGC_has_successfully_CrTW": "已经成功邀请了{{x}}个朋友",
  "FoodC_update_TDwr_XZVd": "我的{{brand2}}",
  "FoodC_all_No_reward_ocGJ": "没有奖励",
  "FoodC_1_Everyone_is_Tdgu": "大家都在领取奖励",
  "FoodC_CGC_UFif_hfJM": "奖励会在邀请成功后的{{num}}小时内发放入您的账号，您可在{{app_name}}-钱包-券中查看",
  "FoodC_C_Go_VWzj": "去下单",
  "FoodC_toast__SVYJ": "领取成功",
  "FoodC_C_The_end_yvSK": "截止至",
  "FoodC_center_Ospi_SNsH": "累计已省",
  "FoodC_page_eUPd_sDHg": "规则",
  "FoodD_Activity_Number_of_OgKt": "人数奖励",
  "FoodC_pause_tfPj_IAoM": "您将在{{date}}重新加入{{brand}}并被自动扣款{{money}}。{{tc}}",
  "soda-d-i18n---无任何记录": "无任何记录",
  "FoodC_C_Bonded_package_lroO": "券包市场",
  "当前无活动": "Currently no events",
  "该城市暂无活动": "Currently no events in this city",
  "网络异常，请稍后再试": "Network error, try again later",
  "手机号码错误": "Invalid mobile number",
  "选择国家或地区": "Select country or region",
  "暂时不需要%s的服务": "I am currently not using %s's service",
  "支付成功": "Payment Successful",
  "我们正在努力": "Working on it",
  "是，半年之内不要给我发邮件": "I’m sure, don’t send me emails for the next six months",
  "将优惠分享到": "Share Promotion to",
  "邮件退订结果": "E-mail Unsubscribe Result",
  "暂无活动": "Currently no events",
  "推荐记录": "Referral History",
  "恭喜你获得%s张优惠券，快去使用吧": "Congrats! You have %s coupons. Use them to order now",
  "X推D": "X-D Referral",
  "X推C": "Customer Referred by X",
  "不支持现金支付": "Cash not accepted",
  "该浏览器不支持自动复制": "Automatic copying is not supported by this browser",
  "对此类邮件不感兴趣": "I am not interested in these kinds of e-mails",
  "退订理由：": "Reason for Unsubscribing:",
  "输入手机号码获取优惠券": "Type your phone number and get coupons!",
  "现金": "Cash",
  "仅限%s": "%s Only",
  "规则详情": "Detailed Rules",
  "您邀请的好友需通过%s下单，通过其他渠道下单不发放邀请奖励": "The friend you invite must make an order via the %s. Orders made through other channels will not fulfill the requirement to receive an invite reward",
  "折": "OFF",
  "是，再也不要给我发邮件了": "I’m sure, don't send me any emails in the future",
  "输入手机号": "Enter mobile number",
  "在这里购买！": "Click to Purchase!",
  "阶梯奖励": "Ladder Rewards",
  "很遗憾，未能领取成功": "Sorry! Unable to claim this promo",
  "活动时间": "Promotion Time",
  "隐藏": "Hide",
  "%s可用": "Available in %s",
  "满%s可用": "over %s",
  "T&C": "Terms and Conditions",
  "售罄": "SOLD OUT",
  "当前定位城市：": "Current city set：",
  "券包": "Coupon Pack",
  "附近没有商家": "No nearby stores",
  "选择城市": "Select city",
  "您已经成功领取优惠券": "Coupon successfully received",
  "兑换成功": "Redeemed",
  "更多详情": "More Details",
  "可获得%s优惠券": "Get %s Coupons",
  "轻松来获得一个非常棒的优惠券包": "To get a great offer of a coupon pack",
  "请开启定位": "Please turn on location service",
  "支付超时！": "Paid Timeout",
  "奖励会在邀请成功后的48小时内发放入您的账号，您可在%s-钱包-券中查看": "Rewards will be sent to your account within 48 hours after a successful invite. To view them, open your %s > Wallet > Coupons",
  "去升级": "Update",
  "复制成功": "Copied",
  "免配送费": "Free Delivery",
  "重新加载": "Reload",
  "支付": "Pay",
  "%s日内有效": "Valid for %s days",
  "有效时间:": "valid until:",
  "当前无有效活动": "No active promotions ",
  "周四": "Thursday",
  "本优惠活动仅限%s[在线支付]的订单享受，不可提现转赠": "This promotion is only available for %s [online payment] orders. It cannot be transferred or redeemed for cash",
  "通过SNS邀请": "Share via Social Media",
  "分钟": "Min",
  "恭喜你！兑换成功！": "Congrats! Coupon redeemed!",
  "优惠券中心": "Coupon Center",
  "我的优惠券": "My Coupons",
  "网络繁忙，请稍后再试": "Network busy, try again later",
  "很遗憾您无法接收我们的最新消息了，感谢您使用%s！": "We're sorry you won't be receiving our latest messages. Thank you for using %s!",
  "奖励进度": "Reward Progress",
  "领取成功": "Claimed!",
  "周五": "Friday",
  "周二": "Tuesday",
  "券包购买已超限制": "Coupon purchase has exceeded the limit",
  "与": "and",
  "最高抵扣%s": "Applicable to %s",
  "您邀请的好友必须是%s新用户，每人仅限享受1次新用户首单优惠，同一手机号、同一设备、同一支付账号视为同一用户": "The friend you invite must be a new %s customer. Each person can only get a “New Customer First Order Discount” one time. The same phone number, same device, and same payment account are all considered to be the same customer",
  "邮件频率过高": "E-mails are sent too frequently",
  "收餐人手机号与领取券时输入的手机号和账号注册手机号必须为同一号码": "The phone number of the order recipient, the number entered upon claiming the coupon, and the number used to register the account must be the same ",
  "当前手机系统不支持自动复制": "Current mobile system does not support auto copy",
  "仅售": "Only",
  "兑换优惠券": "Redeem Coupon",
  "收起": "Tuck up",
  "大家都在领取奖励": "Everyone is getting rewards",
  "配送费": "Delivery Fee",
  "支付中": "Pay",
  "在您的订单中使用这些优惠！": "Use them in the orders you choose! Can be used with store promotion!",
  "已获得奖励": "Earned Rewards",
  "SMS": "SMS",
  "恭喜你获得一张%s优惠券，快去使用吧": "Congrats! You have a %s coupon. Use it to order now",
  "复制": "Copy",
  "我知道了": "I know",
  "《法律声明与隐私政策》": "Terms & Conditions and Privacy Policy ",
  "OFF": "OFF",
  "兑换中": "In progress",
  "周三": "Wednesday",
  "已参与过同类型活动</br>快去下单吧": "You have participated in a similar promotion</br>Go ahead and order!",
  "看看其他的活动": "Look at other activities",
  "周一": "Monday",
  "隐私政策》": "Privacy Policy",
  "推荐有奖": "Referral Rewards",
  "同意": "I Agree",
  "周日": "Sunday",
  "在购买后的一个月内都可以使用": "Available for use within one month after purchase",
  "优惠券使用门槛及有效期详见券面提示，未在券有效期限内使用完毕的，不可继续使用": "Coupon usage and validity period details are displayed on the coupon. Coupons cannot be used after the period of validity ",
  "邮件退订": "Unsubscribe from E-mails",
  "输入兑换码": "Enter promotion code",
  "详细规则": "View Event Rules",
  "Share": "Share",
  "你已经打开过这个礼盒了": "You already opened this gift",
  "知道了": "Later",
  "运营活动": "Operations Promotion",
  "定位服务未开启，需要先开启定位服务才能参与活动！": "You can only participate the event with location service on",
  "购买": "Buy",
  "券账号：%s": "Current account: %s",
  "查看其它活动": "View Other Events",
  "新用户额外惊喜奖励": "New customers enjoy extra rewards",
  "%s天后过期": "Expires in %s days",
  "单笔订单金额满足优惠券使用门槛金额时方可使用，单笔订单只能使用一张优惠券，使用优惠后，如订单金额超出部分需用户自行支付": "A coupon can be used when an order meets the coupon's usage threshold amount. Only one coupon can be used per order. Any amount in excess of the coupon's value must be paid by the customer",
  "可抵扣%s": "Applicable to %s",
  "仅限%s使用": "%s Only",
  "领取记录": "Rewards History",
  "立即兑换": "Redeem Now",
  "如果您退订邮件，可能会错过重要消息，您确定不再接收%s给您发送的邮件吗？": "By unsubscribing, you may miss out on important messages. Are you sure you want to stop receiving these emails?",
  "周六": "Saturday",
  "待入账": "Pending",
  "点击领取": "Claim Now",
  "落地页": "Landing Page",
  "C运营弹窗": "C Operations Pop-up Window",
  "POS": "POS Terminal",
  "添加信用卡/借记卡": "Add credit/debit card",
  "活动说明": "Event Descriptions",
  "Email": "Email",
  "领取失败": "Unable to claim",
  "网络似乎存在问题，请稍后重试": "There seems to be a network issue, please try again later",
  "分享给朋友，一起享优惠吧！": "Share with friends to enjoy discounts!",
  "《法律声明": "Terms & Conditions",
  "当日有效": "Valid on the day",
  "有效期至": "valid until",
  "支付方式": "Payment",
  "邀请": "Invite",
  "等待支付结果": "Waiting for payment result...",
  "应付金额": "Amounts payable",
  "购买含4张$10mxn无门槛券的优惠券包仅需支付$20mxn": "Purchase a pack of 4 coupons of $10mxn each for only $20mxn for you to use at your leisure",
  "是，一年之内不要给我发邮件": "I’m sure, don't send me emails for the next 12 months",
  "仅限新用户使用": "New Customer Only",
  "一经发现任何违规、套现行为，将追回相关金额或者封停账号，且依法追究其法律责任": "Any rules violation or attempt to cash in a coupon may result in a deduction of the amount in question or account suspension. Those in violation may also be held legally accountable",
  "退订": "Unsubscribe",
  "网络不佳，请检查您的网络": "Poor connection. Please check your network connection",
  "重试": "Try again",
  "查看更多": "View",
  "请选择支付方式": "Please select a payment method",
  "%s 人": "%s Person",
  "我的邀请码": "My Unique Referral Code",
  "获取优惠券": "Get Coupons",
  "我的奖励": "My Rewards",
  "C端落地页": "DiDi Food Landing Page",
  "兑换失败": "Unable to redeem",
  "待入账奖励": "Pending Rewards",
  "加载失败": "Unable to load",
  "必须同意用户条款，才能继续下一步": "Please agree to Terms & Conditions and Privacy Policy to use apps",
  "附近商家": "Nearby stores",
  "暂无优惠券": "No coupons available",
  "卡类型": "Card Type",
  "格式不正确": "Incorrect format",
  "订单": "Order",
  "不可用原因": "Invalid reason",
  "请您升级到最新的APP版本，体验外卖功能": "Update to the latest version of the app to start ordering food",
  "邀请一个好友即可获得": "Invite a friend to get coupons",
  "返回首页": "Back to Home",
  "没有更多了": "End of results",
  "去预订食物": "Place an order now",
  "More": "More",
  "有效期至%s": "Valid Until %s",
  "去看看": "Back to Home",
  "兑换失败，请再试一次": "Failed. Try again",
  "确认": "Confirm",
  "已过期": "Expired",
  "本优惠活动仅限[%s]开通%s区域": "This promotion is only available in areas in [%s] where %s is available",
  "此活动尚未在您的城市开通": "Event not yet available in your city",
  "确定": "OK",
  "去使用": "Use Now",
  "%s": "%s",
  "当天过期": "Expires today",
  "去看看其他的": "check out our other promotions",
  "具体活动规则见": "Click the Terms and Conditions  to view the detailed activity rules",
  "条款和条件": "Terms & Conditions",
  "配送": "Delivery",
  "为你的第一单领取优惠": "Receive discounts for your first order!",
  "通用支付": "All Payments",
  "99Pay-余额": "99Pay - Balance",
  "券被领完": "No coupons left",
  "卡": "Card",
  "优惠券已放入": "Coupon sent",
  "关于本活动的任何问题，请联系%s客服": "For any questions regarding this promotion, contact %s customer service at ",
  "邀请好友成为%s新用户，好友领取您送出的红包，并在活动时间截止前完成订单且无退款，无论是否使用领取的红包下单，您均可获得%s[优惠券]奖励": "Invite a friend to become a new %s customer so they can claim a coupon sent from you. If the friend makes an order before the promotion deadline and does not refund the order, no matter if they use your coupon to place the order, you may receive %s [Coupons] Rewards",
  "推荐骑手": "Refer Couriers",
  "活动已结束": "This event has ended",
  "今日领取次数达上限": "Daily limit reached",
  "取消": "Cancel",
  "登录": "Sign In",
  "请登录": "Please login"
}